import { useState } from "react";
import "./search.scss"
import { connect } from 'react-redux';
import {
    loggMeIN,
    rmAuth,
    toggleMenu,
    setCustomerId
} from "./../../../redux/Functions/actions";
const Search = (props) => {
    let { data, filtered, setPageNumber } = props;

    const searcher = async (text) => {
        if (!!data && !!filtered && !!setPageNumber) {
            let searchText = await text?.target.value.toLowerCase();
            let filteredUser = data?.filter((dataCallBack) => {
                console.log("users incoming", dataCallBack);
                if (dataCallBack?.content) {
                    return dataCallBack?.content.toLowerCase().includes(searchText);

                    // setPageNumber(0);
                }
                else if (dataCallBack?.DocName) {
                    return dataCallBack?.DocName.toLowerCase().includes(searchText);
                }
                else if (dataCallBack?.CustomerName) {
                    return dataCallBack?.CustomerName.toLowerCase().includes(searchText);
                    // setPageNumber(0);
                }
                else if (dataCallBack?.customerName) {
                    return dataCallBack?.customerName.toLowerCase().includes(searchText);
                    // setPageNumber(0);
                }
                else if (dataCallBack?.docName) {
                    return dataCallBack?.docName.toLowerCase().includes(searchText);
                    // setPageNumber(0);
                }
                else if (dataCallBack?.Pershkrimi) {
                    return dataCallBack?.Pershkrimi.toLowerCase().includes(searchText);
                    // setPageNumber(0);
                }
                else if (dataCallBack?.DocName) {
                    return dataCallBack?.DocName.toLowerCase().includes(searchText);
                    // setPageNumber(0);
                }
                else if (dataCallBack?.Name) {
                    return dataCallBack?.Name.toLowerCase().includes(searchText);
                    // setPageNumber(0);
                }
            });
            filtered(filteredUser);
            setPageNumber(0);
        }
    };

    return (
        <>

            <div className="input-type">
                {/* <i className="fa fa-search"/> */}
                <input
                    type="text"
                    className="search-area"
                    placeholder="Kerko"
                    onChange={searcher}
                    aria-label="Search"

                />
            </div>
        </>


    );
};
const mapStateToProps = state => {
    return {
        lang: state.data.lang,
        isLoading: state.data.isLoading,
        loggedIn: state.data.loggedIn,
        menu: state.data.menu,
        user_id: state.data.user_id,
        customer_id: state.data.customer_id,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        loggMeIN: () => dispatch(loggMeIN()),
        rmAuth: () => dispatch(rmAuth()),
        toggleMenu: () => dispatch(toggleMenu()),
        setCustomerId: (customer_id) => dispatch(setCustomerId(customer_id)),

        // loadingOff: () => dispatch(loadingOff()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
