import { setAuth } from './actions';
import { SET_AUTH, RM_AUTH, LANG_AL, LANG_EN, LOADING_ON, LOADING_OFF, LOGGEDIN, USER_ID, CUSTOMER_ID, ROLE, MENU, EXTERNAL_DB, ACTIVEMENU } from './types';


const INITIAL_STATE = {
    lang: 'en',
    isLoading: true,
    loggedIn: false,
    auth: '',
    user_id: null,
    customer_id: null,
    role: null,
    menu: true,
    external_db: true,
    activemenu: 1
};

const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case LANG_AL:
            return {
                ...state, lang: 'al',
            };
        case LANG_EN:
            return {
                ...state, lang: 'en',
            };
        default: return state;
        case LOADING_ON:
            return {
                ...state, isLoading: true
            };
        case LOADING_OFF:
            return {
                ...state, isLoading: false
            };
        case LOGGEDIN:
            if (state.loggedIn === false) {
                return {
                    ...state, loggedIn: true
                }
            } else return {
                ...state, loggedIn: false
            };
        case SET_AUTH:
            return {
                ...state, auth: action.auth
            };
        case RM_AUTH:
            return {
                ...state, auth: ''
            };
        case USER_ID:
            return {
                ...state, user_id: action.user_id
            };
        case CUSTOMER_ID:
            return {
                ...state, customer_id: action.customer_id
            };
        case ROLE:
            return {
                ...state, role: action.role
            };
        case MENU:
            return {
                ...state, menu: !state.menu
            }
        case EXTERNAL_DB:
            return {
                ...state, external_db: action.external_db
            }
        case ACTIVEMENU:
            return {
                ...state, activemenu: action.activemenu
            }

    }

};

export default reducer;