import React, { useState } from "react";
import { Link } from "react-router-dom";
import AdminMenuData from "./adminmenudata";
import "./menu.scss";
import Logo from "../../images/logo-v2.svg";
import { connect } from "react-redux";
import {
  loggMeIN,
  rmAuth,
  toggleMenu,
  setActiveMenu,
  setRole,
  setCustomerId,
  // setExternalDB
} from "./../../redux/Functions/actions";
import Icon from "../../images/logout.svg"
import AdminMenuData10 from "./menuRole10";
import AdminMenuRoli3 from "./adminMenuRoli3";
import AdminMenuRoli2 from "./adminMenuRoli2";
const AdminMenu = (props) => {
  console.log("alabalaPropsa", props);
  const [dropdown, setDropdown] = useState(false);
  // const [active, setActive] = useState(1);
  const logOut = () => {
    props.loggMeIN();
    props.rmAuth();
    props?.setExternalDB(null);
    props?.setActiveMenu(1);
    window.localStorage.clear();
  };
  let wwidth = window.innerWidth;
  console.log("width", wwidth);

  if (props.menu === true) {
    const g = new Date().getDate();
    const monthNames = [
      "Janar",
      "Shkurt",
      "Mars",
      "Prill",
      "Maj",
      "Qershor",
      "Korrik",
      "Gusht",
      "Shtator",
      "Tetor",
      "Nentor",
      "Dhjetor",
    ];

    const d = new Date();

    return (
      <>
        <div className="row">
          <button
            onClick={() => props?.toggleMenu()}
            className="menuButton"
            style={{
              left: wwidth > 750 ? (props.menu ? "18.7%" : "10px") : "10px",
            }}
          >
            {props.menu ? (
              <i class="close"></i>
            ) : (
              <i class="fa fa-bars"></i>
            )}
          </button>
        </div>
        <div className="admin-menu" style={{ transition: "1s ease-in-out" }}>
          <div class="logoTermin">
            <div className="dataTermin">
              <p className="demo3">{g}</p>
              <p className="demo5">{monthNames[d.getMonth()].slice(0, 3)}</p>
              <div>
                <p className="demo4">{new Date().getFullYear()}</p>
              </div>

            </div>
            <div className="termineImg">

              <a href={"/"}>
                <img className="" src={Logo}></img>
              </a>
            </div>
          </div>

          {/* <img src={Logo} alt='logo' width='260px' /> */}

          {props.role === 10 &&
            <ul>
              {AdminMenuData10.map((el, index) => {
                if (el?.more?.length > 0) {
                  return (
                    <>
                      <i
                        className={
                          !dropdown
                            ? "fa fa-chevron-down mr-2 bg-danger"
                            : "fa fa-chevron-right"
                        }
                        style={{
                          position: "absolute",
                          left: "23px",
                          paddingTop: "15px",
                        }}
                        key={index}

                      />
                      <Link
                        to={{}}
                        key={index}
                        onClick={() => {
                          setDropdown(!dropdown);
                          props?.setActiveMenu(el.id);
                        }}
                      >
                        <li className={dropdown === true && "active"}>
                          {"  " + el.name_al}
                        </li>{" "}
                      </Link>

                      {dropdown === true && (
                        <div className="subMenuItems">
                          {" "}
                          {el.more.map((subel, index) => {
                            console.log("artoni", subel, index);
                            return (
                              <Link
                                to={subel.url}
                                key={"sub-" + index}
                                onClick={() => props?.setActiveMenu(subel.id)}
                              >
                                <li
                                  className={
                                    props?.activemenu === subel.id && "active"
                                  }
                                >
                                  {subel.name_al}
                                </li>{" "}
                              </Link>
                            );
                          })}{" "}
                        </div>
                      )}
                    </>
                  );
                } else
                  return (
                    <Link
                      to={el.url}
                      id={"menu-item-" + index}
                      onClick={() => {
                        props?.setActiveMenu(el.id);
                        if (wwidth < 750) {
                          props?.toggleMenu();
                        }
                      }}
                      key={index}
                    >
                      <li className={props?.activemenu === el.id && "active"}>

                        <img src={el.img} />
                        <span className="ml-1"> {el.name_al}
                        </span>

                      </li>{" "}
                    </Link>
                  );
              })}
              <div className="lg-line">
              <Link to={"/"} onClick={logOut} className="">
                <button className="logout">
                       {/* <i className="fa fa-sign-out"></i> */}
                       <img src={Icon}/>
                        <span className="ml-1"> Dilni
                        </span>

                  </button>
              </Link>
              </div>
            </ul>
          }
          {props.role === 3 &&
            <ul>
              {AdminMenuRoli3.map((el, index) => {
                if (el?.more?.length > 0) {
                  return (
                    <>
                      <i
                        className={
                          !dropdown
                            ? "fa fa-chevron-down mr-2 bg-danger"
                            : "fa fa-chevron-right"
                        }
                        style={{
                          position: "absolute",
                          left: "23px",
                          paddingTop: "15px",
                        }}
                        key={index}

                      />
                      <Link
                        to={{}}
                        key={index}
                        onClick={() => {
                          setDropdown(!dropdown);
                          props?.setActiveMenu(el.id);
                        }}
                      >
                        <li className={dropdown === true && "active"}>
                          {"  " + el.name_al}
                        </li>{" "}
                      </Link>

                      {dropdown === true && (
                        <div className="subMenuItems">
                          {" "}
                          {el.more.map((subel, index) => {
                            console.log("artoni", subel, index);
                            return (
                              <Link
                                to={subel.url}
                                key={"sub-" + index}
                                onClick={() => props?.setActiveMenu(subel.id)}
                              >
                                <li
                                  className={
                                    props?.activemenu === subel.id && "active"
                                  }
                                >
                                  {subel.name_al}
                                </li>{" "}
                              </Link>
                            );
                          })}{" "}
                        </div>
                      )}
                    </>
                  );
                } else
                  return (
                    <Link
                      to={el.url}
                      id={"menu-item-" + index}
                      onClick={() => {
                        props?.setActiveMenu(el.id);
                        if (wwidth < 750) {
                          props?.toggleMenu();
                        }
                      }}
                      key={index}
                    >
                      <li className={props?.activemenu === el.id && "active"}>

                        <img src={el.img} />
                        <span className="ml-1"> {el.name_al}
                        </span>

                      </li>{" "}
                    </Link>
                  );
              })}
              <div className="lg-line">
                <Link to={"/"} onClick={logOut} className="">
                  <li className="logout">
                    {/* <i className="fa fa-sign-out"></i> */}
                    <img src={Icon} />
                    <span className="ml-1"> Dilni
                    </span>

                  </li>
                </Link>
              </div>
            </ul>
          }
          {props.role === 2 &&
            <ul>
              {AdminMenuRoli2.map((el, index) => {
                if (el?.more?.length > 0) {
                  return (
                    <>
                      <i
                        className={
                          !dropdown
                            ? "fa fa-chevron-down mr-2 bg-danger"
                            : "fa fa-chevron-right"
                        }
                        style={{
                          position: "absolute",
                          left: "23px",
                          paddingTop: "15px",
                        }}
                        key={index}

                      />
                      <Link
                        to={{}}
                        key={index}
                        onClick={() => {
                          setDropdown(!dropdown);
                          props?.setActiveMenu(el.id);
                        }}
                      >
                        <li className={dropdown === true && "active"}>
                          {"  " + el.name_al}
                        </li>{" "}
                      </Link>

                      {dropdown === true && (
                        <div className="subMenuItems">
                          {" "}
                          {el.more.map((subel, index) => {
                            console.log("artoni", subel, index);
                            return (
                              <Link
                                to={subel.url}
                                key={"sub-" + index}
                                onClick={() => props?.setActiveMenu(subel.id)}
                              >
                                <li
                                  className={
                                    props?.activemenu === subel.id && "active"
                                  }
                                >
                                  {subel.name_al}
                                </li>{" "}
                              </Link>
                            );
                          })}{" "}
                        </div>
                      )}
                    </>
                  );
                } else
                  return (
                    <Link
                      to={el.url}
                      id={"menu-item-" + index}
                      onClick={() => {
                        props?.setActiveMenu(el.id);
                        if (wwidth < 750) {
                          props?.toggleMenu();
                        }
                      }}
                      key={index}
                    >
                      <li className={props?.activemenu === el.id && "active"}>

                        <img src={el.img} />
                        <span className="ml-1"> {el.name_al}
                        </span>

                      </li>{" "}
                    </Link>
                  );
              })}
              <div className="lg-line">
                <Link to={"/"} onClick={logOut} className="">
                  <li className="logout">
                    {/* <i className="fa fa-sign-out"></i> */}
                    <img src={Icon} />
                    <span className="ml-1"> Dilni
                    </span>

                  </li>
                </Link>
              </div>
            </ul>
          }
          {props.role === 1 &&
            <ul>
              {AdminMenuData.map((el, index) => {
                if (el?.more?.length > 0) {
                  return (
                    <>
                      <i
                        className={
                          !dropdown
                            ? "fa fa-chevron-down mr-2 bg-danger"
                            : "fa fa-chevron-right"
                        }
                        style={{
                          position: "absolute",
                          left: "23px",
                          paddingTop: "15px",
                        }}
                        key={index}
                      />
                      <Link
                        to={{}}
                        key={index}
                        onClick={() => {
                          setDropdown(!dropdown);
                          props?.setActiveMenu(el.id);
                        }}
                      >
                        <li className={dropdown === true && "active"}>
                          {"  " + el.name_al}
                        </li>{" "}
                      </Link>

                      {dropdown === true && (
                        <div className="subMenuItems">
                          {" "}
                          {el.more.map((subel, index) => {
                            console.log("artoni", subel, index);
                            return (
                              <Link
                                to={subel.url}
                                key={"sub-" + index}
                                onClick={() => props?.setActiveMenu(subel.id)}
                              >
                                <li
                                  className={
                                    props?.activemenu === subel.id && "active"

                                  }
                                >
                                  {subel.name_al}
                                </li>{" "}
                              </Link>
                            );
                          })}{" "}
                        </div>
                      )}
                    </>
                  );
                } else
                  return (
                    <Link
                      to={el.url}
                      id={"menu-item-" + index}
                      onClick={() => {
                        props?.setActiveMenu(el.id);
                        if (wwidth < 750) {
                          props?.toggleMenu();
                        }
                      }}
                      key={index}
                    >
                      <li className={props?.activemenu === el.id && "active"}>
                        <img src={el.img} className />
                        <span className="ml-1"> {el.name_al}
                        </span>
                      </li>{" "}
                    </Link>
                  );
              })}

               <div className="lg-line">
              <Link to={"/"} onClick={logOut} className="">
                 <button className="logout">
                       <img src={Icon}/>
                        <span className="ml-1"> Dilni
                        </span>

                  </button>
              </Link>
              </div>
            </ul>
          }
        </div>
      </>
    );
  } else {
    return (
      <button
        onClick={() => props?.toggleMenu()}
        className={props.menu ? "menuButton" : "menuButtonBars"}
        style={{ left: wwidth > 750 ? (props.menu ? "21%" : "10px") : "10px" }}
      >
        {props.menu ? (
          <i class="fa fa-chevron-left"></i>
        ) : (
          <i class=""></i>
        )}
      </button>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    lang: state.data.lang,
    isLoading: state.data.isLoading,
    loggedIn: state.data.loggedIn,
    menu: state.data.menu,
    role: state.data.role,
    customer_id: state.data.customer_id,
    activemenu: state.data.activemenu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loggMeIN: () => dispatch(loggMeIN()),
    rmAuth: () => dispatch(rmAuth()),
    setRole: () => dispatch(setRole()),
    setCustomerId: () => dispatch(setCustomerId()),
    toggleMenu: () => dispatch(toggleMenu()),
    setActiveMenu: (id) => dispatch(setActiveMenu(id)),
    // setExternalDB: () => dispatch(setExternalDB()),

    // loadingOff: () => dispatch(loadingOff()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminMenu);
// export default AdminMenu;
