import React from 'react'
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from "../../../axios";
import "./settings.scss";
import {
    loggMeIN,
    rmAuth,
    toggleMenu,
    setCustomerId
} from "./../../../redux/Functions/actions";
import { toast } from "react-toastify";
import { TimePicker } from "antd";
import moment from "moment";
import "antd/dist/antd.css";
import Loader from "../Loader/Loader";


const Setting = (props) => {
    const [settings, setSettings] = useState({});
    const [checkedValue, setChangedValue] = useState();
    const [orariFrom, setOrariFrom] = useState();
    const [loading, setLoading] = useState(true);

    const [orariTo, setOrariTo] = useState();
    const format = "HH:mm";

    const getSettings = () => {
        // axios.get(`/admin/getCustomerSettings/${props?.customer_id}`).then((data) => {
        axios.get(`/customer/getCustomerSettings`).then((data) => {
            // setSettings(data?.data[0]);
            setChangedValue(data?.data[0]);
            setLoading(false);

            console.log("settings.AllowBookingByUser", checkedValue?.AvailableFrom.slice(11, 16));


        }).catch((err) => {
            console.log(err)
        })

    };

    const allowbooking = (e) => {
        // console.log('e', e.currentTarget.checked)
        const ud = Object.assign({}, checkedValue);
        ud.AllowBookingByUsers = e.currentTarget.checked;
        setChangedValue(ud);
        // setSettings(ud);
    };
    const saturday = (e) => {
        // console.log('e', e.target.checked)
        const ud = Object.assign({}, checkedValue);
        ud.BookingOnSaturday = e.currentTarget.checked;
        setChangedValue(ud);
        // setSettings(ud);
    };
    const sunday = (e) => {
        // console.log('e', e.target.checked)
        const ud = Object.assign({}, checkedValue);
        ud.BookingOnSunday = e.currentTarget.checked;
        setChangedValue(ud);
        // setSettings(ud);
    };
    const sms_notify = (e) => {
        // console.log('e', e.currentTarget.checked)
        const ud = Object.assign({}, checkedValue);
        ud.NotificationWithSMS = e.currentTarget.checked;
        setChangedValue(ud);
        // setSettings(ud);
    };
    const mail_notify = (e) => {
        // console.log('e', e.currentTarget.checked)
        const ud = Object.assign({}, checkedValue);
        ud.NotificationWithMail = e.currentTarget.checked;
        setChangedValue(ud);
        // setSettings(ud);
    };
    const auto_approve = (e) => {
        // console.log('e', e.currentTarget.checked)
        const ud = Object.assign({}, checkedValue);
        ud.AutoApproveBooking = e.currentTarget.checked;
        setChangedValue(ud);
        // setSettings(ud);
    };
    const require_confirmation = (e) => {
        // console.log('e', e.currentTarget.checked)
        const ud = Object.assign({}, checkedValue);
        ud.RequireBookingConfirmation = e.currentTarget.checked;
        setChangedValue(ud);
        // setSettings(ud);
    };
    const require_confirmation_before = (e) => {
        // console.log('e', e.currentTarget.checked)
        const ud = Object.assign({}, checkedValue);
        ud.RequireBookingConfirmationBefore = e.currentTarget.value;
        setChangedValue(ud);
        // setSettings(ud);
    };
    const termineCdoMin = (e) => {
        // console.log('e', e.currentTarget.checked)
        const ud = Object.assign({}, checkedValue);
        ud.TermineCdoMin = e.currentTarget.value;
        setChangedValue(ud);
        // setSettings(ud);
    };
    const require_confirmation_sms = (e) => {
        // console.log('e', e.currentTarget.checked)
        const ud = Object.assign({}, checkedValue);
        ud.RequireBookingConfirmationSMS = e.currentTarget.checked;
        setChangedValue(ud);
        // setSettings(ud);
    };
    const require_confirmation_email = (e) => {
        // console.log('e', e.currentTarget.checked)
        const ud = Object.assign({}, checkedValue);
        ud.RequireBookingConfirmationMail = e.currentTarget.checked;
        setChangedValue(ud);
        // setSettings(ud);
    };
    const AvailableNga = (e) => {
        // console.log('e', e.currentTarget.checked)
        const ud = Object.assign({}, checkedValue);
        ud.AvailableFrom = e;
        setChangedValue(ud);
        // setSettings(ud);
    };
    const AvailableDeri = (e) => {
        // console.log('e', e.currentTarget.checked)
        const ud = Object.assign({}, checkedValue);
        ud.AvailableTo = e;
        setChangedValue(ud);
        // setSettings(ud);
    };

    const OrariPrej = (e) => {
        setOrariFrom(e)


    };
    const OrariDeri = (e) => {
        setOrariTo(e)


    };

    console.log("AllowBoking", checkedValue?.AllowBookingByUsers === true ? Number(1) : Number(0))
    console.log("orariTo", orariTo)
    const submitData = (e) => {
        e.preventDefault();

        let dataDoc = {
            allowbooking: checkedValue?.AllowBookingByUsers === true ? 1 : 0,
            saturday: checkedValue?.BookingOnSaturday === true ? 1 : 0,
            sunday: checkedValue?.BookingOnSunday === true ? 1 : 0,
            sms_notify: checkedValue?.NotificationWithSMS === true ? 1 : 0,
            mail_notify: checkedValue?.NotificationWithMail === true ? 1 : 0,
            auto_approve: checkedValue?.AutoApproveBooking === true ? 1 : 0,
            require_confirmation: checkedValue?.RequireBookingConfirmation === true ? 1 : 0,
            require_confirmation_before: Number(checkedValue?.RequireBookingConfirmationBefore),
            TermineCdoMin: Number(checkedValue?.TermineCdoMin),
            AvailableFrom: checkedValue?.AvailableFrom,
            AvailableTo: checkedValue?.AvailableTo,
            require_confirmation_sms: checkedValue?.RequireBookingConfirmationSMS === true ? 1 : 0,
            require_confirmation_email: checkedValue?.RequireBookingConfirmationMail === true ? 1 : 0,
        }
        console.log("dataDoc", dataDoc)
        axios
            // .put(`/admin/customerSettings/${props?.customer_id}`, dataDoc)
            .put(`customer/settings`, dataDoc)
            .then((data) => {
                toast.success("Të dhenat u ndryshuan me sukses");
            })
            .catch((err) => {
                console.log(err);
            });

    };


    useEffect(() => {
        getSettings();
    }, []);

    if (loading === true) {
        return <Loader/>
        } else
    return (
    <>
        <div className="Settings " style={{ left: props?.menu ? '21%' : '1%'}}>
                <h3 className='titujt ml-1'>Settings</h3>
            <div className="settingsContent" style={{ left: props?.menu ? '20%' : '0', width: props?.menu ? "80%" : "100%" }}>
                <form onSubmit={submitData}>
                    <div className="input-design text-left">
                        <label className="ml-2 mr-4 col-md-4 col-8" for={"AllowBookingByUsers"}>
                            <p className=''>Lejo rezervime nga perdoruesi</p>
                        </label>
                        <input type="checkbox" id={"AllowBookingByUsers"} className="switch col-md-2 col-4 col-md-2 col-4" value={checkedValue?.AllowBookingByUsers} defaultChecked={checkedValue?.AllowBookingByUsers} onClick={allowbooking} />
                    </div>
                    <div className="input-design text-left">
                        <label className="ml-2 mr-4 col-md-4 col-8" for={"BookingOnSaturday"}>
                            <p>Lejo rezervim ne diten e Shtunë</p>
                        </label>
                        <input type="checkbox" id={"BookingOnSaturday"} className="switch col-md-2 col-4" value={checkedValue?.BookingOnSaturday} defaultChecked={checkedValue?.BookingOnSaturday} onClick={saturday} />
                    </div>
                    <div className="input-design text-left">
                        <label className="ml-2 mr-4 col-md-4 col-8" for={"BookingOnSunday"}>
                            <p>Lejo rezervim ne diten e Diel</p>
                        </label>
                        <input type="checkbox" id={"BookingOnSunday"} className="switch col-md-2 col-4" value={checkedValue?.BookingOnSunday} defaultChecked={checkedValue?.BookingOnSunday} onClick={sunday} />
                    </div>
                    <div className="input-design text-left">
                        <label className="ml-2 mr-4 col-md-4 col-8" for={"NotificationWithSMS"}>
                            <p>Lajmërimet me sms</p>
                        </label>
                        <input type="checkbox" id={"NotificationWithSMS"} className="switch col-md-2 col-4" value={checkedValue?.NotificationWithSMS} defaultChecked={checkedValue?.NotificationWithSMS} onClick={sms_notify} />
                    </div>
                    <div className="input-design text-left">
                        <label className="ml-2 mr-4 col-md-4 col-8" for={"NotificationWithMail"}>
                            <p>Lajmërimet me E-mail</p>
                        </label>
                        <input type="checkbox" id={"NotificationWithMail"} className="switch col-md-2 col-4" value={checkedValue?.NotificationWithMail} defaultChecked={checkedValue?.NotificationWithMail} onClick={mail_notify} />
                    </div>
                    <div className="input-design text-left">
                        <label className="ml-2 mr-4 col-md-4 col-8" for={"AutoApproveBooking"}>
                            <p>Aprovo automatikisht rezervimin</p>
                        </label>
                        <input type="checkbox" id={"AutoApproveBooking"} className="switch col-md-2 col-4" value={checkedValue?.AutoApproveBooking} defaultChecked={checkedValue?.AutoApproveBooking} onClick={auto_approve} />
                    </div>
                    <div className="input-design text-left">
                        <label className="ml-2 mr-4 col-md-4 col-8" for={"RequireBookingConfirmation"}>
                            <p>Kërkoni konfirmimin e rezervimit</p>
                        </label>
                        <input type="checkbox" id={"RequireBookingConfirmation"} className="switch col-md-2 col-4" value={checkedValue?.RequireBookingConfirmation} defaultChecked={checkedValue?.RequireBookingConfirmation} onClick={require_confirmation} />
                    </div>
                    <div className="input-design text-left">
                        <label for={"RequireBookingConfirmationBefore"} id="kushtet" className="kushtet text-left  col-md-4 mb-1 ml-2 col-7">
                            <p>Kërkoni konfirmimin e rezervimit  <span className='font-weight-bold'>{checkedValue?.RequireBookingConfirmationBefore}</span> ditë përpara</p>
                        </label>
                        <input
                            id={"RequireBookingConfirmationBefore"}
                            className="inputt xona20 col-md-5 col-11"
                            type="number"
                            defaultValue={checkedValue?.RequireBookingConfirmationBefore}
                            value={checkedValue?.RequireBookingConfirmationBefore}
                            onChange={require_confirmation_before}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key) || (checkedValue?.RequireBookingConfirmationBefore?.length > 0)) {
                                    event.preventDefault();
                                }
                            }}
                            required
                        />
                    </div>
                    <div className="input-design text-left">
                        <label for={"TermineCdoMin"} id="kushtet" className="kushtet text-left  col-md-4 mb-1 ml-2 col-7">
                            <p>Shfaq terminet çdo <span className='font-weight-bold'>{checkedValue?.TermineCdoMin}</span> minuta</p>
                        </label>
                        <input
                            id={"TermineCdoMin"}
                            className="inputt xona20 col-md-5 col-11"
                            type="number"

                            defaultValue={checkedValue?.TermineCdoMin}
                            onChange={termineCdoMin}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key) || (checkedValue?.TermineCdoMin?.length > 2)) {
                                    event.preventDefault();
                                }
                            }}
                            required
                        />
                    </div>
                    <div className="row mb-3 input-design">

                        <div className="timePickerSettings text-left col-md-3 col-6 ml-4 ">
                            <p className='pharagaphTime '>Orari i punes Prej</p>
                        </div>

                        <div className="col-md-2 col-4">

                            <TimePicker
                                className="modal-ant-picker pickerOnSettings "
                                value={moment(`${checkedValue?.AvailableFrom}`, format)}
                                format={format}
                                popupStyle={{ zIndex: "999999" }}
                                popupClassName={"selectDate"}
                                onChange={(e, i) => AvailableNga(i)}
                                defaultValue={checkedValue?.AvailableFrom.slice(11, 16)}
                                minuteStep={5}

                            />
                        </div>
                    </div>
                    <div className="row mb-3 input-design">
                        <div className="timePickerSettings text-left col-md-3 col-6 ml-4">
                            <p className='pharagaphTime'>Orari i punes Deri</p>
                        </div>
                        <div className="col-md-2 col-4">
                            <TimePicker
                                className="modal-ant-picker pickerOnSettings"
                                value={moment(`${checkedValue?.AvailableTo}`, format)}
                                defaultValue={checkedValue?.AvailableTo.slice(11, 16)}
                                format={format}
                                popupStyle={{ zIndex: "999999" }}
                                popupClassName={"selectDate"}
                                onChange={(e, i) => AvailableDeri(i)}
                                minuteStep={5}

                            />
                        </div>
                    </div>

                    <div className="input-design text-left">
                        <label className="ml-2 mr-4 col-md-4 col-8" for={"RequireBookingConfirmationSMS"}>
                            <p>Kërkoni SMS për konfirmimin e rezervimit</p>
                        </label>
                        <input type="checkbox" id={"RequireBookingConfirmationSMS"} className="switch col-md-2 col-4" value={checkedValue?.RequireBookingConfirmationSMS} defaultChecked={checkedValue?.RequireBookingConfirmationSMS} onClick={require_confirmation_sms} />
                    </div>
                    <div className="input-design text-left">
                        <label className="ml-2 mr-4 col-md-4 col-8" for={"RequireBookingConfirmationMail"}>
                            <p>Kërkoni E-mail për konfirmimin e rezervimit</p>
                        </label>
                        <input type="checkbox" id={"RequireBookingConfirmationMail"} className="switch col-md-2 col-4" value={checkedValue?.RequireBookingConfirmationMail} defaultChecked={checkedValue?.RequireBookingConfirmationMail} onClick={require_confirmation_email} />
                    </div>
                    <div className="input-design text-left">
                        <button type="submit" className="button_forgot  mr-4 mb-4" >
                            RUAJ
                        </button>
                    </div>
                </form>
            </div>



        </div >
    </>
    )
}

const mapStateToProps = state => {
    return {
        lang: state.data.lang,
        isLoading: state.data.isLoading,
        loggedIn: state.data.loggedIn,
        menu: state.data.menu,
        user_id: state.data.user_id,
        customer_id: state.data.customer_id,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        loggMeIN: () => dispatch(loggMeIN()),
        rmAuth: () => dispatch(rmAuth()),
        toggleMenu: () => dispatch(toggleMenu()),
        setCustomerId: (customer_id) => dispatch(setCustomerId(customer_id)),

        // loadingOff: () => dispatch(loadingOff()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Setting)