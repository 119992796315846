
const MenuData = [
    {
        name_al: "Ballina",
        name_en: "Home",
        url: "/",
        icon: "fa fa-home"
    },
    {
        name_al: "Për Reth",
        name_en: "About",
        url: "#about",
        icon: "fa fa-home"
    },
    {
        name_al: "Produktet",
        name_en: "Products",
        url: "#products",
        icon: "fa fa-home"
    },
    {
        name_al: "Kontakti",
        name_en: "Contact",
        url: "#contact",
        icon: "fa fa-home"
    },
  
]

export default MenuData;