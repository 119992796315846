import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate
} from "react-router-dom";
import './App.scss';
import { render } from "react-dom";
import Menu from './components/menu/menu';
import AdminMenu from './components/menu/adminmenu';
import { useEffect } from 'react';
import Login from './components/login/login';



import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import MobileMenu from './components/menu/mobilemenu';
import CaktoTerminin from "./components/admin/caktoterminin/caktoterminin";
import Terminet from "./components/admin/terminet/terminet";
import { connect } from "react-redux";
import {

} from "./redux/Functions/actions";
import Kompanite from "./components/admin/caktoterminin/kompanite";
import StafiKompanise from "./components/admin/caktoterminin/stafiKompanise";
import StafiAdmin from "./components/admin/doktorat/doktorat";
import EditProfile from "./components/admin/stock/stock.js";
import Register from "./components/admin/register/register";
import ForgetPassword from "./components/admin/forgetPassword/forgetPassword";
import ResetPassword from "./components/admin/resetPassword/resetPassword";
import RegisterSuccess from "./components/admin/riktheFjalkalimin/rikthefjalkalimin";
import ShtoKartelen from "./components/admin/ShtoKartelenEklientit/shtoKartelen";
import Vizitat from "./components/admin/vizitat/vizitat";
import KompanyProfile from "./components/admin/companyProfile/companyProfile";
import Settings from "./components/admin/settings/settings";
import TimeSlots from "./components/admin/caktoterminin/timeSlots";
import AddModalAdmin from "./components/admin/doktorat/addModalAdmin";
import EditDoktorat from "./components/admin/doktorat/editDoktorat";
import MyCalendar from "./components/admin/Calendar/Calendar";
import AddCalendarAppointment from "./components/admin/Calendar/addCalendarAppointment";
import OrariPunes from "./components/admin/doktorat/orariPunes";
import Profile from "./components/admin/MyProfileOtherRoles/Profile";
import Calendar from "./components/admin/Calendar/Calendar";


const App = (props) => {



  // useEffect(()=> {
  //   window.localStorage.setItem('lang', 'en');
  // },[])
  return (
    <div className="App">
      <ToastContainer position="bottom-right" theme="colored" style={{ zIndex: '999999' }} />

      <Router>

        {props.loggedIn == true ? <AdminMenu /> : ''}


        <Routes>
          {/* <Route exact path="/" element={props.loggedIn ? <Dashboard /> :<HomePage />}/> */}
          <Route exact path="/" element={props.loggedIn ? (props?.role === 1 ? <Terminet /> : <Calendar />) : <Login />} />
          {props.loggedIn === true ? <>
            <Route exact path="/terminet" element={<Terminet />} />
            <Route exact path="/caktoterminin" element={<CaktoTerminin />} />
            <Route exact path="/kompanite/:Pershkrimi/:cid" element={<Kompanite />} />
            <Route exact path="/stafiiKompanise/:name/:cid" element={<StafiKompanise />} />
            <Route exact path="/doctorSingle/:cid/:did" element={<TimeSlots />} />
            <Route exact path="/editProfile" element={<EditProfile />} />
            <Route exact path="/kompaniProfili" element={<KompanyProfile />} />
            <Route exact path="/shtoKartelen" element={<ShtoKartelen />} />
            <Route exact path="/vizitat" element={<Vizitat />} />
            <Route exact path="/listimet" element={<StafiAdmin />} />
            <Route exact path="/settings" element={<Settings />} />
            <Route exact path="/shtoStafin" element={<AddModalAdmin />} />
            <Route exact path="/shtoAppointment" element={<AddCalendarAppointment />} />
            <Route exact path="/orariPunes/:pid" element={<OrariPunes />} />
            <Route exact path="/editStaff/:pid" element={<EditDoktorat />} />
            <Route exact path="/calendar" element={<MyCalendar />} />
            <Route exact path="/profile" element={<Profile />} />


            {/* <Route exact path="/admin/members" element={<Members />}/>
          <Route exact path="/admin/partners" element={<Partners />}/>
          <Route exact path="/admin/clients-furnitors" element={<Bashkpuntoret />}/>
          <Route exact path="/admin/purchases" element={<Blerjet />}/>
          <Route exact path="/admin/sales" element={<Shitjet />}/>
        <Route exact path="/admin/statistics" element={<Statistics />}/> */}
          </>
            :
            <>

              {/* <Route exact path="/*" element={<HomePage />} /> */}
              <Route exact path="/*" element={<Login />} />
              <Route exact path="/forgotPassword" element={<ForgetPassword />} />
              <Route exact path="/resetPassword/:uuid" element={<ResetPassword />} />
              <Route exact path="/register" element={<Register />} />
              <Route path="/forgotpasswordMsg" element={<RegisterSuccess />} />

            </>

          }
        </Routes>

      </Router>
    </div>
  )

}
const mapStateToProps = state => {
  return {
    lang: state.data.lang,
    isLoading: state.data.isLoading,
    loggedIn: state.data.loggedIn,
    role: state.data.role

  }
}

const mapDispatchToProps = dispatch => {
  return {
    // toggleLang: (lang) => dispatch(toggleLang(lang)),
    // loadingOn: () => dispatch(loadingOn()),
    // loadingOff: () => dispatch(loadingOff()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
