import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import axios from "../../../axios";
import {
    loggMeIN,
    rmAuth,
    toggleMenu
} from "../../../redux/Functions/actions";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import './resetPassword.scss';




const ResetPassword = (props) => {
    console.log('shabaniasd', props)
    const [userData, setUserData] = useState([]);
    const [gender, setGender] = useState(null);
    const [profesionalInfo, setProfessionalInfo] = useState([]);
    const [passwordChange, setPasswordChange] = useState();
    const params = useParams();
    const navigate = useNavigate();


    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }


    let querry = useQuery();

    console.log("paramks", params)
    const [show, setShow] = useState(false);

    const hidePassword = () => {
        setShow(!show);
    }


    // const [register, setUserData] = useState([]);

    const handleChangePassword = (e) => {
        e.preventDefault();
        // const token = captchaRef.current.getValue();
        // captchaRef.current.reset();
        const passwordat = {
            // oldPass: e?.target.passwordOld.value,
            pass: e?.target.newPassword.value,
            passconfirm: e.target.repetPassword.value,
            uuid: params.uuid,
        };
        console.log("testRpet", passwordat.pasiperseritur)
        console.log("testPasssssss", passwordat.pass)
        setPasswordChange(passwordat);
        if (passwordat.pass != passwordat.passconfirm) {
            toast.error("Passwords doesn't match")
        } else {
            // axios.post(`/user/resetPassword/`, passwordat)
            axios.post(`auth/reset`, passwordat)
                .then((res) => {
                    console.log("pe kqyri", res)
                    toast.success("Your password has been changed ", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    navigate("/login")
                })
                .catch((err) => {
                    console.log("error incoming", err);
                    // alert('shit sndrroj')

                });
        }
    }


    return (
        <div className="container mt-5">
            <div className="resetPassword">
                <form onSubmit={handleChangePassword}>
                    <div className="form-input">
                        {querry.get('active') ? <h1 className="heading-section mt-5">Passwordi juaj...  najsen</h1> : ''}
                        <h1 className="text-left">Ndrysho Fjalkalimin tuaj!</h1>
                        <input type="password" placeholder="Fjalkalimi juaj i ri!" name="newPassword" id="newPassword" className="form-control" required />
                        <input type="password" placeholder=" Perserit Fjalkalimin tuaj te ri!" name="repetPassword" id="repetPassword" className="form-control" required/>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="button_forgot">Ruaj</button>
                    </div>

                </form>
            </div>

            {/* <div className="help mt-5 mb-5">
                <p>{"Need Help ?"}</p>
            </div> */}
        </div >
    );
};
const mapStateToProps = state => {
    return {
        lang: state.data.lang,
        isLoading: state.data.isLoading,
        loggedIn: state.data.loggedIn,
        menu: state.data.menu,
        user_id: state.data.user_id
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loggMeIN: () => dispatch(loggMeIN()),
        rmAuth: () => dispatch(rmAuth()),
        toggleMenu: () => dispatch(toggleMenu())
        // loadingOff: () => dispatch(loadingOff()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);