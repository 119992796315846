
const AdminMenuData = [
    {
        name_al: "Terminet",
        url: "/terminet",
        icon: "fa fa-calendar",
        id: 1,
        img:"../../../images/terminet.svg"
   
       
    },
    {
        name_al: "Cakto Terminin",
        url: "/caktoterminin",
        icon: "fa fa-home",
        id: 2,
        img:"../../../images/cakto-terminin.svg"
    },
    {
        name_al: "Kartela e Klientit",
        url: "/shtoKartelen",
        icon: "fa fa-home",
        id: 3,
         img:"../../../images/kartela-e-klientit.svg"
    },
    {
        name_al: "Vizitat",
        url: "/vizitat",
        icon: "fa fa-home",
        id: 4,
        img:"../../../images/vizitat.svg"
    },
    {
        name_al: "Profili Im",
        url: "/editProfile",
        icon: "fa fa-home",
        id: 5,
        img:"../../../images/profili.svg"
    },

    // {
    //     name_al: "Web Administrimi",
    //     url: "/admin/stock",
    //     icon: "fa fa-home",
    //    id: 9,
    //     more: [
    //         {
    //             name_al: "Partnerët",
    //             url: "/admin/partners",
    //             icon: "fa fa-home",
    //            id: 91
    //         },
    //         {
    //             name_al: "Stafi",
    //             url: "/admin/members",
    //             icon: "fa fa-home",
    //            id: 92
    //         },
    //         {
    //             name_al: "Përkthimet",
    //             url: "/admin/translations",
    //             icon: "fa fa-home",
    //            id: 93
    //         },
    //     ]
    // },


]

export default AdminMenuData;