import React, { useEffect, useState } from "react";
import './caktoterminin.scss';
import axios from '../../../axios';
import { render } from '@testing-library/react';
import { connect } from "react-redux";
import {
  loggMeIN,
  rmAuth,
  toggleMenu
} from "./../../../redux/Functions/actions";
import { useNavigate, useParams } from "react-router";
import ReactPaginate from "react-paginate";
import Search from "../Search/search";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";

const CaktoTerminin = (props) => {

  const [loading, setLoading] = useState(true);
  const [appointments, setappointments] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const productsPerPage = 20;

  const pagesVisited = pageNumber * productsPerPage;
  let displayFilteredData = [];
  const pageCount = Math.ceil(filtered.length / productsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const { params } = useParams();
  const getData = () => {
    axios.get('/user/terminet/getCategories').then(
      data => {
        console.log('appointments', data.data);
        setappointments(data.data);
        setLoading(false);
        setFiltered(data?.data)
      }
    ).catch(err => { console.log('Errori', err) });
  }

  let auth = window.localStorage.getItem('terminettoken');
  useEffect(() => {
    if (auth !== null || auth !== undefined) {
      getData();
    }
  }, []);


  if (loading === true) {
    return <Loader />
  } else
    return (
      <>
        <div className="testing">
          <div className="">
            <Search data={appointments} filtered={setFiltered} setPageNumber={setPageNumber} />
          </div>
        </div>
        <div className='caktoterminin' style={{ left: props?.menu ? '20%' : '0', width: props?.menu ? "80%" : "100%" }}>
          <h3 class="titujt" >Kategoria</h3>
          <div className="terminetContainer" style={{ left: props?.menu ? '20%' : '0', width: props?.menu ? "80%" : "100%" }}>
            <div className="row terminetRow">
              {displayFilteredData = filtered?.slice
                (pagesVisited, pagesVisited + productsPerPage)?.map
                (filtered => {
                  return (
                    <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12 mt-4">
                      <Link to={`/kompanite/${filtered?.Pershkrimi}/${filtered?.ID}`} className="categoryName" >
                        <div className={"kategori-items"}>
                          <div className="row align-items-center">
                            <div className="col-xl-5 col-lg-5 col-md-3 col-4">
                              <div className="circle ml-2">
                                <img src={`${process.env.REACT_APP_UPLOADS + filtered?.llogo}`} className="CompanyLogo" />
                              </div>
                            </div>
                            <div className="col-xl-7 col-lg-7 col-md-9 col-8 ">
                              <h4 className="kategoryTitle text-left" >{filtered?.Pershkrimi}</h4>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
            <div className="paginationn mt-5">
              <div className="paginationWrapperPatien text-right">
                {(appointments?.length >= productsPerPage) ?
                  <ReactPaginate
                    previousLabel={<i className='fa fa-angle-double-left' />}
                    nextLabel={<i className='fa fa-angle-double-right' />}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  /> : ''
                }
              </div>
            </div>
          </div>
        </div>
      </>


    )

}
const mapStateToProps = state => {
  return {
    lang: state.data.lang,
    isLoading: state.data.isLoading,
    loggedIn: state.data.loggedIn,
    menu: state.data.menu,
    user_id: state.data.user_id
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loggMeIN: () => dispatch(loggMeIN()),
    rmAuth: () => dispatch(rmAuth()),
    toggleMenu: () => dispatch(toggleMenu())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CaktoTerminin);