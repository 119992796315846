import axios from 'axios';
import { toast } from 'react-toastify';
let store;

export const injectStore = _store => {
  store = _store
}
let apitoken = window.localStorage.getItem('terminettoken');
// console.log('token', apitoken);
const defaultInstance = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
        Authorization: 'Bearer ' + apitoken,
    },
});
defaultInstance.interceptors.response.use(req =>{
    console.log('interceptorsreq', req);
    
    // console.log('interceptors', isLoading);
    if(req.status === 401){
    //    store.getState().data.isLoading = true ;
    //    store.getState().data.loggedIn = false;
    //    toast.error('Authentication Expired!');
    //    window.location.assign('/');
    window.localStorage.clear();
    window.location.reload();
    }
    return req
})


export default defaultInstance;
