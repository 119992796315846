import React from "react";
import { Link } from "react-router-dom";
import './menu.scss';
import MenuData from './menudata';
import Logo from '../../images/bl_harrisia.png';
import AlFlag from '../../images/tn_al-flag.gif';
import UsFlag from '../../images/tn_us-flag.gif';
import { connect } from "react-redux"

import {
  toggleLang,

} from "../../redux/Functions/actions"
const Menu = (props) => {
  // const lang = window.localStorage.getItem('lang');
  // const changeLang = () => {
  //     if (lang === 'en') {
  //         window.localStorage.setItem('lang', 'al');
  //         window.location.reload();
  //     }
  //     if (lang === 'al') {
  //         window.localStorage.setItem('lang', 'en');
  //         window.location.reload();
  //     }
  // }
  // console.log('menuProps',props)
  return (
    <div className="menu">
      <div className='row p-2'>
        <div className='col-4'><a href={'/'}><img src={Logo} style={{ width: '160px' }}></img></a></div>
        <div className='col-8 pt-4'>
          {MenuData.map((el, index) => {

            return <a href={el.url} className='ml-3 link-light' key={index}>{props?.lang == 'en' ? el.name_en : el.name_al}</a>
          })}

          {props?.lang == 'en' ?
            <a className='ml-3 link-light' onClick={() => props.toggleLang('al')}><img src={AlFlag} width={'20px'} key='al-flag' /></a> :
            <a className='ml-3 link-light' onClick={() => props.toggleLang('en')}><img src={UsFlag} width={'20px'} key='us-flag' /></a>}
          {/* <Link to='/login' className='ml-3' key='login'><i className='fa fa-sign-in' /></Link> */}
        </div>
      </div>
    </div>
  )

}
const mapStateToProps = state => {
  return {
    lang: state.data.lang,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleLang: (lang) => dispatch(toggleLang(lang)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
