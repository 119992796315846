
const AdminMenuRoli2 = [
    {
        name_al: "Terminet",
        url: "/calendar",
        icon: "fa fa-calendar",
        id: 1,
        img: "../../../images/terminet.svg"
    },

    {
        name_al: "Profili Im",
        url: "/profile",
        icon: "fa fa-user",
        id: 5,
        img: "../../../images/profili.svg"
    },


]

export default AdminMenuRoli2;