import axios from "../../../axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./addNewModal.scss";
import Close from '../../../images/light-close.svg';
import ModalPortal from "../../../portal/ModalPortal";

import "./shtoKartelen.scss"

function ProductsModal({user_id, getClinicsData, handleClose, isOpen}) {
    console.log('po hapna', user_id);
    // const [isOpen, setOpen] = useState(true);
    const [isEdit, setEdit] = useState(false);
    const [isdelete, setDelete] = useState(false);
    const [image, setImage] = useState();
    const [isImageEdit, setImageEdit] = useState(false);
    const [data, setData] = useState();
    const [addClinic, setAddClinic] = useState([]);

    const [customerId, setCustomerId] = useState();
    // const navigate = useNavigate();


    const getData = () => {
        axios.get("user/userCards/getCustomersHisMapping")
            .then((response) => {
                console.log("Clinics123", response.data);
                setData(response.data);

            })

            .catch((err) => console.log("error", err));
    };

    const getAllClinics = () => {
        axios
            // .get(`profile/getClinics/${props?.user_id}`)
            .get(`user/userCards`)
            .then((response) => {
                console.log("setDoctors", response?.data);
                setAddClinic(response.data);
                // setFiltered(response?.data)
                // setLoading(false);
            })

            .catch((err) => console.log("error", err));
    };

    const roleId = e => {
        console.log("nitanika", e.target.value)
        setCustomerId(e.target.value);

    }

    useEffect(() => {
        getData();
        getAllClinics();
    }, []);

    const submitData = (e) => {
        e.preventDefault();

        let credentials = {
            roleId: customerId,
            cardNumber: e?.target.cardNumber.value,
        };

        axios
            // .post(`profile/setClinics/${props?.user_id}`, credentials)
            .post(`user/userCards`, credentials)
            .then((res) => {
                console.log("res",res)
                    handleClose();
                    getClinicsData();
            })
            .catch((err) => {
                console.log("axiosErr", err);
            });
    };
   

    console.log('dataObject', data);
    if (isOpen) {
        return (
            <ModalPortal wrapperId="KartelaAddModal">
                <div className="wholePage" onClick={() => handleClose()}></div>
                <div className='shtoKartelenModal'>
                <img src={Close} onClick={() => handleClose()} className='closeModal' />
                    <div className='addModal-wrapper'>

                        <div className="modalAddKartel p-0 mt-5">

                            <form onSubmit={submitData} >
                                <div className="form-input">
                                    <p className="text-left">Numri i karteles:</p>
                                    <input type="text" name="cardNumber" id="cardNumber" className="email_input mb-3" placeholder="Numri i karteles" required />
                                </div>
                                <div className="form-group">
                                    <select className='selectCustomer' onChange={roleId} required title="This field is required" >
                                        <option value="" selected disabled>Ju lutem zgjedhni njeren nga kompanite</option>
                                        {data?.map((el) => {
                                            return (
                                                <option value={el?.id} className="options">
                                                    {el?.customerName}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>

                                {/* <button type="submit" className="form-control  btn btnLogin submit">Ruaj</button>
                                     */}
                                <button type="submit" className="button_forgot" >
                                    Ruaj
                                </button>
                            </form>

                        </div>
                    </div></div>
                    </ModalPortal>
        )
    } else return null

}


export default ProductsModal;