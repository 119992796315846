import { LANG_AL, LANG_EN, LOADING_OFF, LOADING_ON, LOGGEDIN, SET_AUTH, RM_AUTH, USER_ID, ROLE, MENU, ACTIVEMENU, CUSTOMER_ID, EXTERNAL_DB } from './types';


export const toggleLang = (lang) => {
    if (lang === 'al') {
        return {
            type: LANG_AL,
        };

    } else {
        return {
            type: LANG_EN,
        };
    }
};

export const loadingOn = () => {
    return { type: LOADING_ON }
};
export const loadingOff = () => {
    return { type: LOADING_OFF }
};
export const loggMeIN = () => {
    return { type: LOGGEDIN }
}

export const setAuth = (auth) => {
    return { type: SET_AUTH, auth: auth }
}
export const rmAuth = () => {
    return { type: RM_AUTH, auth: null }
}
export const setUserId = (id) => {
    return { type: USER_ID, user_id: id }
}
export const setCustomerId = (id) => {
    return { type: CUSTOMER_ID, customer_id: id }
}
export const setRole = (role) => {
    return { type: ROLE, role: role }
}
export const toggleMenu = () => {
    return { type: MENU }
}
export const setExternalDB = (external) => {
    return { type: EXTERNAL_DB, external_db: external }
}
export const setActiveMenu = (activemenu) => {
    return { type: ACTIVEMENU, activemenu: activemenu }
}