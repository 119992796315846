import React, { useEffect, useState } from "react";
import './doktorat.scss';
import axios from '../../../axios';
import { render } from '@testing-library/react';
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import {
    loggMeIN,
    rmAuth,
    toggleMenu,
    setCustomerId,
    setExternalDB
} from "../../../redux/Functions/actions";
import { Link } from "react-router-dom";
import Search from "../Search/search";
import AddModalAdmin from "./addModalAdmin";
import EditDoktorat from "./editDoktorat";
import Loader from "../Loader/Loader";


const StafiAdmin = (props) => {
    console.log("PRopsStaf", props?.external_db)
    const [addClinic, setAddClinic] = useState([]);
    const [data, setData] = useState();
    const [search, setSearch] = useState("");
    const [filtered, setFiltered] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);


    const productsPerPage = 20;
    // const productsPerList = 50;
    const pagesVisited = pageNumber * productsPerPage;
    let displayFilteredData = [];
    const pageCount = Math.ceil(filtered.length / productsPerPage);

    const changePage = ({ selected }) => {
        // console.log(selected)
        setPageNumber(selected);
    };


    const getAllClinics = () => {
        axios
            // .get(`/admin/getCustomerDoctors/${props?.customer_id}`)
            .get(`/customer/users/all`)
            .then((response) => {
                console.log("setDoctors", response?.data);
                setAddClinic(response?.data);
                setFiltered(response?.data)
                setLoading(false);
            })

            .catch((err) => console.log("error", err));
    };


    useEffect(() => {
        getAllClinics();
    }, []);

    console.log(open)

    if (loading === true) {
        return <Loader/>
        } else
        return (
            <>
                        <Search data={addClinic} filtered={setFiltered} setPageNumber={setPageNumber} />
                <div className='stafiAdmin' style={{ left: props?.menu ? '20%' : '0' }}>
                    
                        <h3 className="titujt">Stafi</h3>
                    <div class="table-responsive" >
                        <div className="tableResponsiveStafiAdmin" style={{ left: props?.menu ? '20%' : '0', width: props?.menu ? "80%" : "100%" }}>
                            <table class="table"  >
                                <thead>
                                    <tr className="list-item-head-new">
                                        <th className="col-md-2 col-4 text-left pl-4 tableContent">Emri</th>
                                        <th className="col-md-2 col-4 text-left pl-4 tableContent">Mbiemri</th>
                                        <th className="col-md-2 col-4 text-left pl-4 tableContent">Titulli</th>
                                        <th className="col-md-2 col-4 text-left pl-4 tableContent">Specializimi</th>
                                        <th className="col-md-2 col-4 text-left pl-4 tableContent">Aktiv</th>
                                        <th className="col-md-2 col-4 text-left pl-4 tableContent">Online</th>
                                        <th className="col-md-2 col-4 text-left pl-4 tableContent">Ndrysho</th>
                                    </tr>
                                </thead>

                                {displayFilteredData = filtered?.slice
                                    (pagesVisited, pagesVisited + productsPerPage)?.map
                                    (filtered => {
                                        return (
                                            <>
                                                <tr className="list-item">
                                                    <td className="col-md-2 col-4 text-left pl-4 tableContentStafiAdmin">{filtered.Name} </td>
                                                    <td className="col-md-2 col-4 text-left pl-4 tableContentStafiAdmin">{filtered?.SureName}</td>
                                                    {/* {filtered?.ValidatedOn?.length > 0 ? <td className='col-md-2 col-4 text-left pl-4 tableContentStafiAdmin text-start'>{(filtered?.ValidatedOn)?.slice(0, 10)}</td> : <td className='col-md-2 col-4 text-left pl-4 tableContentStafiAdmin text-start'></td>}
                                                {filtered?.Validated === false ? <td className='col-md-2 col-4 text-left pl-4 tableContentStafiAdmin  pl-5 col-3 '><i className='fa fa-times' /></td> : <td className='col-md-2 col-4 text-left pl-4 tableContentStafiAdmin pl-5 col-3'><i className='fa fa-check' /></td>} */}
                                                    <td className="col-md-2 col-4 text-left pl-4 tableContentStafiAdmin">{filtered?.titulli}</td>
                                                    <td className="col-md-2 col-4 text-left pl-4 tableContentStafiAdmin">{filtered?.specializimi}</td>
                                                    <td className="col-md-2 col-4 text-left pl-4 tableContentStafiAdmin">{filtered?.isActive === true ? <i className="fa fa-check"></i> : <i className="fa fa-times"></i>}</td>
                                                    <td className="col-md-2 col-4 text-left pl-4 tableContentStafiAdmin">{filtered?.AwailableOnline === true ? <i className="fa fa-check"></i> : <i className="fa fa-times"></i>}</td>
                                                    <td className='col-md-2 col-4 text-left pl-4 tableContentStafiAdmin  pl-5 col-3 '><Link to={"/editStaff/" + filtered?.id}><i className="fa fa-pencil-square-o iconCursor" /></Link></td>

                                                </tr>

                                            </>
                                        )
                                    })}
                            </table>

                            <div className="paginationn">
                                <div className="paginationWrapperPatien text-right">
                                    {(addClinic?.length >= productsPerPage) ?
                                        <ReactPaginate
                                            previousLabel={<i className='fa fa-angle-double-left' />}
                                            nextLabel={<i className='fa fa-angle-double-right' />}
                                            pageCount={pageCount}
                                            onPageChange={changePage}
                                            containerClassName={"paginationBttns"}
                                            previousLinkClassName={"previousBttn"}
                                            nextLinkClassName={"nextBttn"}
                                            disabledClassName={"paginationDisabled"}
                                            activeClassName={"paginationActive"}
                                        /> : ''
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                {/* <button  onClick={<AddModalAdmin user_id={props?.user_id}  />} >+</button> */}
                {props?.external_db === true ? "" :
                    <Link className='addStaf' to="/shtoStafin">+</Link>
                    }

                {/* </div> */}
            </>
        )
}
const mapStateToProps = state => {
    return {
        lang: state.data.lang,
        isLoading: state.data.isLoading,
        loggedIn: state.data.loggedIn,
        menu: state.data.menu,
        user_id: state.data.user_id,
        customer_id: state.data.customer_id,
        external_db: state.data.external_db,


    }
}

const mapDispatchToProps = dispatch => {
    return {
        loggMeIN: () => dispatch(loggMeIN()),
        rmAuth: () => dispatch(rmAuth()),
        toggleMenu: () => dispatch(toggleMenu()),
        setCustomerId: (customer_id) => dispatch(setCustomerId(customer_id)),

        // loadingOff: () => dispatch(loadingOff()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(StafiAdmin);
// export default

