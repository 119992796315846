import { useState } from "react";
import "./forgetPassword.scss";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import axios from "../../../axios";
import {
    loggMeIN,
    rmAuth,
    toggleMenu
   } from "../../../redux/Functions/actions";
   import Loader from "../Loader/Loader";


const ForgotPassword = (props) => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(true);

    console.log(email);
    const navigate = useNavigate();

    const submitData = (e) => {
        e.preventDefault();
        let credentials = {
            email: e?.target.email.value,
        };

        axios
            .post("/auth/forgetPassword", credentials)
            .then((data) => {
                if (data.data.error === true) {
                    toast.error("Ky email nuk është valid!");
                } else {
                    toast.success(
                        `Email with instructions on changing password was set to email: ${credentials.email}`
                    );
                    setTimeout(() => {

                        navigate("/forgotpasswordMsg")
                    }, 1000);
                }
            })
            .catch((err) => {
                console.log("axiosErr", err);
            });
    };

    return (
        <>
            <div className="container">
                    <div className="forgotPassword">
                        <form onSubmit={submitData} >
                            <h3 className="mb-5">Rikthe fjalëkalimin</h3>
                            <h5 className="text-center mb-5">Shkruani emailin tuaj dhe do të pranoni një link për të rikthyer fjalëkalimin</h5>
                            <div className="form-input">
                                <p className="text-left">Emaili juaj:</p>
                                <input
                                    type="email"
                                    name="email"
                                    className="email_input"
                                    placeholder={
                                        "Email"
                                    }
                                    required
                                />
                            </div>
                            <button type="submit" className="button_forgot" >
                                Dergo
                            </button>
                        </form>
                    </div>
                </div>
        </>
    );
};
const mapStateToProps = state => {
    return {
      lang: state.data.lang,
      isLoading: state.data.isLoading,
      loggedIn: state.data.loggedIn,
      menu: state.data.menu,
      user_id: state.data.user_id
    }
  }
  const mapDispatchToProps = dispatch => {
    return {
      loggMeIN: () => dispatch(loggMeIN()),
      rmAuth: () => dispatch(rmAuth()),
      toggleMenu: () => dispatch(toggleMenu())
    }
  }
  export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
