import axios from "../../../axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./addModalAdmin.scss";
import Loader from "../Loader/Loader";
import { connect } from "react-redux";
import {
    loggMeIN,
    rmAuth,
    toggleMenu,
    setCustomerId
} from "../../../redux/Functions/actions";
import { useNavigate, Link } from "react-router-dom";
import userProfile from "../../../images/defaultImage.jpg"
import { OrariPunes } from "./orariPunes";



const AddModalAdmin = (props) => {
    const navigate = useNavigate();
    console.log('po hapn11111a', props);
    const [isOpen, setOpen] = useState(true);
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [awailableOnline, setAwailableOnline] = useState();
    const [disabled, setDisabled] = useState(false);
    const [image, setImage] = useState(null);
    const [rolet, setRolet] = useState();
    const [duration, setDuration] = useState(30);

    const getRole = () => {
        axios
            // .get(`/admin/getCustomerUser/${pid}/${props?.customer_id}`)
            .get(`customer/appointments/getCostumerUserRoles`)
            .then((data) => {
                setRolet(data?.data);
                setLoading(false);

                console.log("userrrrrrrrrrrrrrrrr", data?.data);
                // setisloading(true);
            })
            .catch((err) => {
                toast.error("Something went wrong!");
                console.log(err);
            });
    };

    useEffect(() => {
        getRole();
    }, []);

    const Name = (e) => {



        const ud = Object.assign({}, awailableOnline);
        ud.Name = e.target.value;
        setAwailableOnline(ud);
        setDisabled(true);


    };
    const SureName = (e) => {
        const ud = Object.assign({}, awailableOnline);
        ud.SureName = e.target.value;
        setAwailableOnline(ud);
        setDisabled(true);


    };


    const specializimi = (e) => {
        const ud = Object.assign({}, awailableOnline);
        ud.specializimi = e.target.value;
        setAwailableOnline(ud);
        setDisabled(true);

    };

    const titulli = (e) => {
        const ud = Object.assign({}, awailableOnline);
        ud.titulli = e.target.value;
        setAwailableOnline(ud);
        setDisabled(true);

    };
    const Bio = (e) => {
        const ud = Object.assign({}, awailableOnline);
        ud.Bio = e.target.value;
        setAwailableOnline(ud);
        setDisabled(true);

    }


    const AwailableOnline = (e) => {
        const ud = Object.assign({}, awailableOnline);
        ud.AwailableOnline = e.currentTarget.checked === true ? 1 : 0;
        setAwailableOnline(ud);
        setDisabled(true);

    };
    const AllowBookingByUsers = (e) => {
        const ud = Object.assign({}, awailableOnline);
        ud.AllowBookingByUsers = e.currentTarget.checked === true ? 1 : 0;
        setAwailableOnline(ud);
        setDisabled(true);

    };

    const HasUserAccount = (e) => {
        const ud = Object.assign({}, awailableOnline);
        ud.HasUserAccount = e.currentTarget.checked === true ? 1 : 0;
        setAwailableOnline(ud);

    };
    const email = (e) => {
        const ud = Object.assign({}, awailableOnline);
        ud.email = e.target.value;
        setAwailableOnline(ud);
    };
    const DefaultDuration = (e) => {
        const ud = Object.assign({}, awailableOnline);
        ud.DefaultDuration = e.target.value;
        setAwailableOnline(ud);
    };
    const handleRoleName = (e) => {
        const ud = Object.assign({}, awailableOnline);
        ud.RoleID = e.target.value;
        setAwailableOnline(ud);
    };

    const isActive = (e) => {
        const ud = Object.assign({}, awailableOnline);
        ud.isActive = e.currentTarget.checked === true ? 1 : 0;
        setAwailableOnline(ud);
        setDisabled(true);

    };
    const changeImage = (e) => {
        // const ud = Object.assign({}, awailableOnline);
        // ud.photo = e.target.files[0];
        // setImage(ud);
        // setDisabled(true);
        // setAwailableOnline(ud);
        if (e.target.files && e.target.files[0]) {
            setImage(URL.createObjectURL(e.target.files[0]));
        }
        const ud = Object.assign({}, awailableOnline);
        ud.photo = e.target.files[0];
        setAwailableOnline(ud);
        console.log("picture", ud);
    };


    useEffect(() => {
        const ud = Object.assign({}, awailableOnline);
        ud.isActive = ""
        ud.AllowBookingByUsers = ""
        ud.AwailableOnline = ""
        ud.HasUserAccount = ""
        setAwailableOnline(ud);
    }, [])

    console.log("firstiktglr", awailableOnline)

    // awailableOnline.photo = stateImg;
    const submitData = (e) => {
        e.preventDefault();


        const formData = new FormData();
        formData.append("photo", awailableOnline?.photo);
        formData.append("isActive", awailableOnline?.isActive);
        // formData.append("BookingOnWednesday", awailableOnline?.BookingOnWednesday);
        // formData.append("BookingOnThursday", awailableOnline?.BookingOnThursday);
        // formData.append("BookingOnFriday", awailableOnline?.BookingOnFriday);
        // formData.append("BookingOnSaturday", awailableOnline?.BookingOnSaturday);
        // formData.append("BookingOnSunday", awailableOnline?.BookingOnSunday);
        // formData.append("BookingOnTuesday", awailableOnline?.BookingOnTuesday);
        // formData.append("BookingOnMonday", awailableOnline?.BookingOnMonday);
        formData.append("AllowBookingByUsers", awailableOnline?.AllowBookingByUsers);
        formData.append("AwailableOnline", awailableOnline?.AwailableOnline);
        formData.append("Bio", awailableOnline?.Bio);
        formData.append("titulli", awailableOnline?.titulli);
        formData.append("specializimi", awailableOnline?.specializimi);
        formData.append("SureName", awailableOnline?.SureName);
        formData.append("Name", awailableOnline?.Name);
        formData.append("email", awailableOnline?.email);
        formData.append("DefaultDuration", awailableOnline?.DefaultDuration ? awailableOnline?.DefaultDuration : duration);
        formData.append("UserID", props?.user_id);
        formData.append("RoleID", awailableOnline?.RoleID);
        formData.append("HasUserAccount", awailableOnline?.HasUserAccount);



        // awailableOnline.photo = image?.photo;
        console.log("hej", awailableOnline);


        axios
            // .post(`/admin/addCustomerUser/${props?.customer_id}`, formData)
            .post(`/customer/users/addCustomerUser`, formData)
            .then((res) => {
                console.log("res", res)
                // props?.getData();
                console.log("po",res?.data?.response[0])
                if (res?.data?.response[0].inserted_id===0) {

                    toast.error(res?.data?.response[0].Porosia)
                } else {
                    toast.success(res?.data?.response[0].Porosia)
                    navigate("/listimet")

                }
            })
            .catch((err) => {
                console.log("axiosErr", err);
            });
    };


    console.log('dataObject', data);
    if (loading === true) {
        return <Loader/>
        } else
        return (
            <>
            <div className="testing">
            <div className="">
                <i className="goBack" aria-hidden="true" onClick={() => navigate(-1)} style={{ left: props?.menu ? '23%' : '6%' }} />
            </div>
        </div>
            <div className="addStaff" style={{ left: props?.menu ? '20%' : '0', width: props?.menu ? "70%" : "80%" }}>
            <h3 className="titujt">Shto Stafin</h3>


                <div className="addStaffStatic" style={{ left: props?.menu ? '20%' : '0', width: props?.menu ? "70%" : "80%" }}>
                    <div className="addStafForm">
                        <form onSubmit={submitData} >
                            <div className="profile-image-box-Add-Staff  text-center">
                                <div className="foto">
                                    <img
                                        src={image === null ? userProfile : image}
                                        alt="Logo e kompanis"
                                        width="200px"
                                        className="img-fluid"
                                    />
                                </div>
                                <div className="buttonatAddStaf">
                                    {
                                        <label
                                            htmlFor="profile-picture-input"
                                            className="profile-picture-add-Staf"
                                        >
                                            <h6 className="mt-2">Shto foto</h6>
                                            <input
                                                type="file"
                                                style={{ cursor: "pointer" }}
                                                id="profile-picture-input"
                                                onChange={changeImage}
                                                className="hidden-hidden"
                                            />
                                        </label>
                                    }

                                    {/* {!!image && <button onClick={submitData} className="btn changePicBtn" > Save Image </button>} */}
                                </div>

                            </div>
                            <div className="form-input">
                                <input type="text" name="name" id="name" className="form-control mb-3 " required placeholder="Emri" onChange={Name} />
                                <input type="text" name="SureName" id="SureName" className="form-control mb-3" required placeholder="Mbiemri" onChange={SureName} />
                                <input type="text" name="titulli" id="titulli" className="form-control mb-3" required placeholder="titulli" onChange={titulli} />
                                <input type="text" name="specializimi" id="specializimi" className="form-control mb-3" required placeholder="Specializimi" onChange={specializimi} />
                                {/* <input type="text" name="photo" id="photo" className="form-control mb-3" required placeholder="Photo" onChange={photo} /> */}
                                <div className="titulli">
                                    <input
                                        type="email" className="form-control mb-3"
                                        onChange={email}
                                        placeholder={"Email"}
                                    ></input>
                                </div>
                                <textarea type="text" name="Bio" id="Bio" className="form-control mb-3 bio" placeholder="Bio" onChange={Bio} />

                                <div className="input-design text-left">
                                    <label className="col-md-6 p-0"><p className="">Roli</p></label>

                                    <select className='col-md-6 mb-2 p-2 roletAddSelect' onChange={handleRoleName} title="This field is required" >

                                        <option selected disabled>Ju lutem zgjedhni </option>
                                        {rolet?.map((el) => {
                                            console.log("el", el)
                                            return (
                                                <option
                                                    required
                                                    value={el.ID}
                                                    selected={
                                                        Number(awailableOnline?.RoleID) ===
                                                            el?.ID
                                                            ? "selected"
                                                            : false

                                                    }
                                                    className="options">
                                                    {el?.Description}

                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>

                                <div className="input-design text-left">

                                    <label className=" col-10 col-md-11 p-0" for={"isActive"}>
                                        <p>Has User Account</p>
                                    </label>
                                    <input type="checkbox" id={"BookingOnSaturday"} className="switch col-md-1 col-2" onClick={HasUserAccount} />
                                </div>
                                <div className="input-design text-left">
                                    <label className=" col-10 col-md-11 p-0" for={"AwailableOnline"}>
                                        <p>E disponueshme online</p>
                                    </label>
                                    <input type="checkbox" className="switch col-md-1 col-3" onClick={AwailableOnline} />
                                </div>
                                <div className="input-design text-left">
                                    <label className=" col-10 col-md-11 p-0" for={"AllowBookingByUsers"}>
                                        <p>Lejo rezervime nga përdoruesi</p>
                                    </label>
                                    <input type="checkbox" className="switch col-md-1 col-2" onClick={AllowBookingByUsers} />
                                </div>
                                <div className="input-design text-left">

                                    <label className=" col-10 col-md-11 p-0" for={"isActive"}>
                                        <p>Aktiv</p>
                                    </label>
                                    <input type="checkbox" id={"BookingOnSaturday"} className="switch col-md-1 col-2" onClick={isActive} />
                                </div>
                                <div className="input-design text-left">
                                    <label for={"TermineCdoMin"} id="kushtet" className="kushtet  col-md-11 mb-1 col-12 p-0 ">
                                        <p>Shfaq terminet çdo <span className='font-weight-bold'>{awailableOnline?.DefaultDuration ? awailableOnline?.DefaultDuration : duration}</span> minuta</p>
                                    </label>
                                    <input
                                        id={"TermineCdoMin"}
                                        className="inputt xona20 col-md-1 col-12"
                                        type="number"
                                        defaultValue={awailableOnline?.DefaultDuration ? awailableOnline?.DefaultDuration : duration}
                                        onChange={DefaultDuration}
                                        onKeyPress={(event) => {

                                            if (!/[0-9]/.test(event.key) || (awailableOnline?.DefaultDuration?.length > 2)) {

                                                event.preventDefault();

                                            }

                                        }}
                                        required
                                    />
                                </div>


                                {/* <p>Lejo rezervime ditën e </p> */}

                                {/* <div className="daysDiv">
                                    <div className="days">
                                        <div className="input-design">
                                            <label className="daysLabel" for={"isActive"}>
                                                <p>Hënë</p>
                                            </label>
                                            <input type="checkbox" id={"BookingOnSaturday"} className="switch col-md-1 col-2" onClick={BookingOnMonday} />
                                        </div>
                                    </div>

                                    <div className="days">
                                        <div className="input-design">
                                            <label className="daysLabel" for={"isActive"}>
                                                <p>Martë</p>
                                            </label>
                                            <input type="checkbox" id={"BookingOnSaturday"} className="switch col-md-1 col-2" onClick={BookingOnTuesday} />
                                        </div>
                                    </div>

                                    <div className="days">
                                        <div className="input-design">
                                            <label className="daysLabel" for={"isActive"}>
                                                <p>Mërkurë</p>
                                            </label>
                                            <input type="checkbox" id={"BookingOnSaturday"} className="switch col-md-1 col-2" onClick={BookingOnWednesday} />
                                        </div>
                                    </div>

                                    <div className="days">
                                        <div className="input-design">
                                            <label className="daysLabel" for={"isActive"}>
                                                <p>Enjte</p>
                                            </label>
                                            <input type="checkbox" id={"BookingOnSaturday"} className="switch col-md-1 col-2" onClick={BookingOnThursday} />
                                        </div>
                                    </div>

                                    <div className="days">
                                        <div className="input-design">
                                            <label className="daysLabel" for={"isActive"}>
                                                <p>Premte</p>
                                            </label>
                                            <input type="checkbox" id={"BookingOnSaturday"} className="switch col-md-1  col-2" onClick={BookingOnFriday} />
                                        </div>
                                    </div>

                                    <div className="days">
                                        <div className="input-design">
                                            <label className="daysLabel" for={"BookingOnSaturday"}>
                                                <p>Shtunë</p>
                                            </label>
                                            <input type="checkbox" className="switch col-md-1 col-2" onClick={BookingOnSaturday} />
                                        </div>
                                    </div>
                                    <div className="days">
                                        <div className="input-design">
                                            <label className="daysLabel" for={"BookingOnSunday"}>
                                                <p>Diel</p>
                                            </label>
                                            <input type="checkbox" className="switch col-md-1 col-2" onClick={BookingOnSunday} />
                                        </div>
                                    </div>

                                </div> */}
                                <div className="form-group">
                                    <button type="submit" disabled={!disabled} className="form-control  btn btnLogin submit">Ruaj</button>
                                </div>
                            </div>
                        </form>
                    </div >

                </div>
            </div >
            </>
        );
    

}

const mapStateToProps = state => {
    return {
        lang: state.data.lang,
        isLoading: state.data.isLoading,
        loggedIn: state.data.loggedIn,
        menu: state.data.menu,
        user_id: state.data.user_id,
        customer_id: state.data.customer_id,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loggMeIN: () => dispatch(loggMeIN()),
        rmAuth: () => dispatch(rmAuth()),
        toggleMenu: () => dispatch(toggleMenu()),
        // loadingOff: () => dispatch(loadingOff()),
        setCustomerId: (customer_id) => dispatch(setCustomerId(customer_id)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddModalAdmin);