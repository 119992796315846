
const AdminMenuData10 = [
    {
        name_al: "Terminet",
        url: "/calendar",
        icon: "fa fa-calendar",
        id: 1,
        img: "../../../images/terminet.svg"
    },
    {
        name_al: "Stafi",
        url: "/listimet",
        icon: "fa-light fa-users",
        id: 2,
        img: "../../../images/stafi.svg"
    },

    {
        name_al: "Profili i Kompanisë",
        url: "/kompaniProfili",
        icon: "fa fa-home",
        id: 3,
        img: "../../../images/profili-i-kompanise.svg"
    },
    {
        name_al: "Settings të Kompanisë",
        url: "/settings",
        icon: "fa fa-cog",
        id: 4,
        img: "../../../images/setting-te-kompanise.svg"
    },

    {
        name_al: "Profili Im",
        url: "/editProfile",
        icon: "fa fa-user",
        id: 5,
        img: "../../../images/profili.svg"
    },


]

export default AdminMenuData10;