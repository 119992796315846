import React from 'react';

import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
// REDUX
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import {store, presisted} from './redux/store';
import {injectStore} from './axios';
injectStore(store);
// import store from './redux/store';
ReactDOM.render(
  // <Provider store={store}>
  //   <React.StrictMode>
  //     <App />
  //   </React.StrictMode>
  // </Provider>
  
  
  <Provider store={store}>
   <PersistGate loading={null} persistor={presisted}>  
    <React.StrictMode>
   {/* <Suspense fallback={(<div>Loading ~~~</div>)} > */}
     <App />
   {/* </Suspense> */}
    </React.StrictMode>
   </PersistGate>
 </Provider>,
document.getElementById('root'),

);