import axios from "../../../axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./editKartelen.scss";
import Close from '../../../images/light-close.svg'
import ModalPortal from "../../../portal/ModalPortal";
// import "./shtoKartelen.scss"
import { useNavigate, useParams } from "react-router";


function EditModalKartel({props,user_id,setPid,getCardNumber,getClinicsData,isEditOpen,getNameOfCard,handleEditClose}) {
    console.log('user_id, getCardNumber PROPSSSS ', props, setPid)
    console.log('po hapna', setPid);
    const [isOpen, setOpen] = useState(true);
    const [isEdit, setEdit] = useState(false);
    const [isdelete, setDelete] = useState(false);
    const [image, setImage] = useState();
    const [isImageEdit, setImageEdit] = useState(false);
    const [data, setData] = useState();
    const [addClinic, setAddClinic] = useState([]);
    const [cardNumber, setCardNumber] = useState(props?.CardNumber);
    const [userData, setUserData] = useState([]);
    const [customerId, setCustomerId] = useState();
    // const navigate = useNavigate();
    const { pid } = useParams();


    const cardNumber1 = (e) => {
        console.log(e.target.value)
        const ud = Object.assign({}, cardNumber);
        ud.card_number = e.target.value;
        setCardNumber(ud);

    };
    
    const submitData = (e) => {
        e.preventDefault();
   let data = {
    cardNumber : cardNumber
   }
        axios
            .put(`user/userCards/editCustomersHisMapping/${props.id}`, cardNumber)
            .then((res) => {
                console.log("res", res?.data)
                handleEditClose()
                getClinicsData();
            })
            .catch((err) => {
                console.log("axiosErr", err);
            });
    };


    console.log('dataObject', data);
    if (isEditOpen) {
        return (
            <ModalPortal wrapperId="KartelaEditModal">
                <div className="wholePage" onClick={() => handleEditClose()}></div>
                <div className='editCardForm'>
                <img src={Close} onClick={() =>handleEditClose()} className='closeModal' />
                    <div className='editModal-wrapper'>

                        <div className="modalEditKartel p-0 mt-5">

                            <form onSubmit={submitData} >
                                
                            <h6 className="text-left" >Institucioni:<span className="font-weight-bold pl-2">{props?.CustomerName}</span></h6>
                            <div className="kartela d-flex"> 
                            <p className="text-left">Numri i karteles:</p>
                                <input
                                    type="text"
                                    className="email_input mb-3 ml-2"
                                    placeholder="Numri i karteles"
                                    defaultValue={props?.CardNumber}
                                    onChange={cardNumber1}
                                />
                            </div>
                              
                              
                                    


                                {/* <div className="form-input">
                                    <p className="text-left">Numri i karteles:</p>
                                    <input type="number" name="card_number" id="card_number" className="email_input mb-3" placeholder="Numri i karteles" required />
                                </div> */}
                                {/* <div className="form-group">
                                    <select className='selectCustomer' onChange={roleId} required title="This field is required" >
                                        <option value="" selected disabled>Ju lutem zgjedhni njeren nga kompanite</option>
                                        {data?.map((el) => {
                                            return (
                                                <option value={el?.id} className="options">
                                                    {el?.customerName}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div> */}

                                {/* <button type="submit" className="form-control  btn btnLogin submit">Ruaj</button>
                                     */}
                                <button type="submit" className="button_EditKartel" >
                                    Ruaj
                                </button>
                            </form>

                        </div>
                    </div></div>
                    </ModalPortal>
        )
    } else return null

}


export default EditModalKartel;