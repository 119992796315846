import React, { useState, useEffect, useRef } from "react";
import "./register.scss";
import axios from "../../../axios";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';

const Register = (props) => {
    const [show, setShow] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [register, setRegister] = useState([]);
    const [birthday, setBirthday] = useState(new Date());
    const navigate = useNavigate();
    const hidePassword = () => {
        setShow(!show);
    }
    const name = (e) => {
        const ud = Object.assign({}, register);
        ud.name = e.target.value;
        setRegister(ud);
        setDisabled(true);
    };
    const surname = (e) => {
        const ud = Object.assign({}, register);
        ud.surename = e.target.value;
        setRegister(ud);
        setDisabled(true);
    };
    const parentName = (e) => {
        const ud = Object.assign({}, register);
        ud.parentName = e.target.value;
        setRegister(ud);
        setDisabled(true);
    };
    const emergencyContact = (e) => {
        const ud = Object.assign({}, register);
        ud.emergencyContact = e.target.value;
        setRegister(ud);
        setDisabled(true);
    };
    const email = (e) => {
        const ud = Object.assign({}, register);
        ud.email = e.target.value;
        setRegister(ud);
        setDisabled(true);
    };
    const password = (e) => {
        const ud = Object.assign({}, register);
        ud.pass = e.target.value;
        setRegister(ud);
        setDisabled(true);
    }
    const phone = (e) => {
        const ud = Object.assign({}, register);
        ud.phone = e.target.value;
        setRegister(ud);
    };
    const checkboxHandler = (e) => {
        const ud = Object.assign({}, register);
        ud.gender = Number(e.target.value);
        setRegister(ud);
        setDisabled(true);
    };
    const birthdate = (e) => {
        let myDOB = new Date(e);
        myDOB.setDate(myDOB.getDate());
        const ud = Object.assign({}, register);
        ud.dob = myDOB.toISOString();
        setRegister(ud);
        setBirthday(e);
        setDisabled(true);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios
            .post("auth/register", register)
            .then((res) => {
                setDisabled(false);
                if (res?.data?.name === "SequelizeUniqueConstraintError") {
                    toast.error("this email already exist")
                } else {
                    // window.location.href = (res?.data?.link)
                    // alert("testbiro")
                    // let credentials = {
                    //     email: "testim@gashi.com",
                    //     pass: "Temp@2022!"
                    // };
                    // let encrypt = sign(credentials);
                    toast.success(
                        `To register successfully please check your email: ${register.email}`
                    );
                    setTimeout(() => {
                        navigate("/")
                    }, 1000);
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error("Something went wrong!");
            });
    };

    return (
        <div className="container">
            <div className="register-form">
                <form onSubmit={handleSubmit} >
                    <div>
                        <h1 className="mt-5 mb-5">Regjistrohu</h1>
                    </div>
                    <div className="form-input">
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <label htmlFor="phone" id="kushtet" className="kushtet text-left ">
                                    Emer
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder={"Emri"}
                                        required
                                        onChange={name}
                                    />
                                </label>
                            </div>
                            <div className="col-lg-6 col-12">
                                <label htmlFor="phone" id="kushtet" className="kushtet text-left ">
                                    Mbiemer
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder={"Mbiemri"}
                                        required
                                        onChange={surname}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="check_box ">
                            <div className="radio-wrapper">
                                <p className="text-left pe-4 text-dark  mr-2">
                                    {"Gjinia :"}
                                </p>
                                <div>
                                    <input
                                        type="radio"
                                        name="myGroupName"
                                        id="male"
                                        value="1"
                                        onChange={checkboxHandler}
                                        required
                                    />
                                    <label id="kushtet" for="male">
                                        {"Mashkull"}
                                    </label>
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        name="myGroupName"
                                        id="female"
                                        value="2"
                                        onChange={checkboxHandler}
                                        required
                                    />
                                    <label id="kushtet" for="female">
                                        {"Femër"}
                                    </label>
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        name="myGroupName"
                                        id="divers"
                                        value="3"
                                        onChange={checkboxHandler}
                                        required
                                    />
                                    <label id="kushtet" for="divers">
                                        {"Tjeter"}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <label htmlFor="phone" id="kushtet" className="kushtet text-left ">
                            E-mail:
                            <input
                                className="form-control"
                                type="email"
                                placeholder={"Email"}
                                onChange={email}
                                required
                            />
                        </label>
                        <div className="form-groupi">
                            <label htmlFor="phone" id="kushtet" className="kushtet text-left ">
                                Fjalkalimi
                                <input
                                    id="password-field"
                                    type={!show ? "password" : "text"}
                                    className="form-control mb-3"
                                    placeholder="Fjalëkalimi"
                                    required
                                    onChange={password} />
                                <span
                                    toggle="#password-field"
                                    className={show ? "fa fa-fw fa-eye field-icon toggle-password" : "fa fa-fw fa-eye-slash field-icon toggle-password"}
                                    onClick={hidePassword} />
                            </label>
                        </div>
                        <label htmlFor="phone" id="kushtet" className="kushtet text-left ">
                            Numri i Telefonit
                            <input
                                type="number"
                                className="form-control xona20"
                                placeholder={"Numri i telefonit"}
                                onChange={phone}
                                required
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key) || (register?.phone?.length > 8)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </label>
                        <label htmlFor="phone" id="kushtet" className="kushtet text-left ">
                            Ditelindja
                            <DatePicker
                                onChange={birthdate}
                                selected={birthday}
                                required
                                className="form-control mb-2"
                                dateFormat="yyyy-MM-dd"
                                placeholderText="YYYY/MM/DD"
                            />
                        </label>
                        <label htmlFor="phone" id="kushtet" className="kushtet text-left ">
                            Kontakti Emergjent
                            <input
                                type="text"
                                className="form-control"
                                placeholder={"emergencyContact"}
                                onChange={emergencyContact}
                                required
                            />
                        </label>
                        <label htmlFor="phone" id="kushtet" className="kushtet text-left ">
                            Emri i Prindit
                            <input
                                className="form-control"
                                type="text"
                                placeholder={"Emri i prindit"}
                                onChange={parentName}
                                required
                            />
                        </label>
                        <div className="form-group">
                            <button type="submit" disabled={!disabled} className="form-control  btn btnLogin submit">Regjistrohu</button>
                        </div>
                        <div className="form-group d-md-flex justify-content-center">
                            <p className='pe-2'>Keni llogari? </p>
                            <a href="/">Kyçuni</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default Register;