import axios from "../../../axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./profile.scss";
// import "./shtoKartelen.scss"
import { useNavigate, useParams } from "react-router";
import { connect } from 'react-redux';
import {
    loggMeIN,
    rmAuth,
    toggleMenu,
    setCustomerId,
    setExternalDB
} from "../../../redux/Functions/actions";
import { Navigate, Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import comapny from "../../../images/building.jpg"







function EditDoktorat(props) {
    console.log('po hapna', props);

    const [data, setData] = useState();
    const [addClinic, setAddClinic] = useState([]);
    const [cardNumber, setCardNumber] = useState(props?.getCardNumber);
    const [userData, setUserData] = useState([]);
    const [image, setImage] = useState(null);
    const [rolet, setRolet] = useState();
    const [roli, setRoli] = useState();
    const [loading, setLoading] = useState(true);

    const [active, setActive] = useState(false);
    const [disabledPass, setDisabledPass] = useState(false);
    const [passwordChange, setPasswordChange] = useState();

const [show, setShow] = useState(false);

 const hidePassword = () => {
        setShow(!show);
    }


    const navigate = useNavigate();
    // const { pid } = useParams();
    // console.log("Pid", pid)

    const getCurrentUsers = () => {
        if (props?.role === 2) {
            axios
                .get(`customerUser/users/single/${props?.user_id}`)
                .then((data) => {
                    setUserData(data?.data[0]);
                    setLoading(false);

                    console.log("userdata", data?.data[0]);
                    // setisloading(true);
                })
                .catch((err) => {
                    toast.error("Something went wrong!");
                    console.log(err);
                });
        }
        else if (props?.role === 3) {
            axios
                .get(`receptionist/users/single/${props?.user_id}`)
                .then((data) => {
                    setUserData(data?.data[0]);
                    setLoading(false);

                    console.log("userdata", data?.data[0]);
                    // setisloading(true);
                })
                .catch((err) => {
                    toast.error("Something went wrong!");
                    console.log(err);
                });
        }


    };
    const getRole = () => {
        if (props?.role === 2) {
            axios
                // .get(`/admin/getCustomerUser/${pid}/${props?.customer_id}`)
                .get(`customerUser/appointments/getCostumerUserRoles`)
                .then((data) => {
                    setRolet(data?.data);
                    setLoading(false);

                    console.log("userrrrrrrrrrrrrrrrr", data?.data);
                    // setisloading(true);
                })
                .catch((err) => {
                    toast.error("Something went wrong!");
                    console.log(err);
                });
        }
        else if (props?.role === 3) {
            axios
                // .get(`/admin/getCustomerUser/${pid}/${props?.customer_id}`)
                .get(`receptionist/appointments/getCostumerUserRoles`)
                .then((data) => {
                    setRolet(data?.data);
                    setLoading(false);

                    console.log("userrrrrrrrrrrrrrrrr", data?.data);
                    // setisloading(true);
                })
                .catch((err) => {
                    toast.error("Something went wrong!");
                    console.log(err);
                });
        }
    }

    useEffect(() => {
        getRole();
    }, []);

    const Name = (e) => {
        const ud = Object.assign({}, userData);
        ud.Name = e.target.value;
        setUserData(ud);


    };
    const SureName = (e) => {
        const ud = Object.assign({}, userData);
        ud.SureName = e.target.value;
        setUserData(ud);


    };


    const specializimi = (e) => {
        const ud = Object.assign({}, userData);
        ud.specializimi = e.target.value;
        setUserData(ud);
        console.log("Specializimi", e.target.value)
    };

    const titulli = (e) => {
        const ud = Object.assign({}, userData);
        ud.titulli = e.target.value;
        setUserData(ud);

    };
    const Bio = (e) => {
        const ud = Object.assign({}, userData);
        ud.Bio = e.target.value;
        setUserData(ud);

    }
    const AwailableOnline = (e) => {
        const ud = Object.assign({}, userData);
        ud.AwailableOnline = e.currentTarget.checked;
        setUserData(ud);

    }
    const AllowBookingByUsers = (e) => {
        const ud = Object.assign({}, userData);
        ud.AllowBookingByUsers = e.currentTarget.checked;
        setUserData(ud);

    };


    const isActive = (e) => {
        const ud = Object.assign({}, userData);
        ud.isActive = e.currentTarget.checked;
        setUserData(ud);

    };

    const email = (e) => {
        const ud = Object.assign({}, userData);
        ud.email = e.target.value;
        setUserData(ud);
    };
    const DefaultDuration = (e) => {
        const ud = Object.assign({}, userData);
        ud.DefaultDuration = e.target.value;
        setUserData(ud);
    };
    const handleRoleName = (e) => {
        const ud = Object.assign({}, userData);
        ud.RoleID = e.target.value;
        setUserData(ud);
    };

    const password = (e) => {
        const ud = Object.assign({}, userData);
        ud.pass = e.target.value;
        setUserData(ud);
        setDisabledPass(true);

    }


    const submitData = (e) => {
        e.preventDefault();
        let dataDoc = {
            AllowBookingByUsers: userData?.AllowBookingByUsers === true ? 1 : 0,
            AwailableOnline: userData?.AwailableOnline === true ? 1 : 0,
            BookingOnMonday: userData?.BookingOnMonday === true ? 1 : 0,
            BookingOnSaturday: userData?.BookingOnSaturday === true ? 1 : 0,
            BookingOnSunday: userData?.BookingOnSunday === true ? 1 : 0,
            BookingOnThursday: userData?.BookingOnThursday === true ? 1 : 0,
            BookingOnTuesday: userData?.BookingOnTuesday === true ? 1 : 0,
            BookingOnWednesday: userData?.BookingOnWednesday === true ? 1 : 0,
            BookingOnFriday: userData?.BookingOnFriday === true ? 1 : 0,
            isActive: userData?.isActive === true ? 1 : 0,
            Name: userData?.Name,
            SureName: userData?.SureName,
            photo: userData?.photo,
            specializimi: userData?.specializimi,
            titulli: userData?.titulli,
            Bio: userData?.Bio,
            HasUserAccount: userData?.HasUserAccount === true ? 1 : 0,
            email: userData?.email,
            RoleID: Number(userData?.RoleID),
            UserID: props?.user_id,
            DefaultDuration: userData?.DefaultDuration
        }
        if (props?.role === 2) {

            axios
                // .put(`/admin/editCustomerUser/${pid}/${props?.customer_id}`, dataDoc)
                .put(`customerUser/users/edit/${props?.user_id}`, dataDoc)
                .then((res) => {
                    console.log("res", res?.data)
                    toast.success('Të dhënat u përditësuan me sukses!');
                    // navigate("/listimet")

                })
                .catch((err) => {
                    console.log("axiosErr", err);
                    toast.error('Të dhënat  nuk janë përditësuar!');
                });
        }
        else if (props?.role === 3) {
            axios
                // .put(`/admin/editCustomerUser/${pid}/${props?.customer_id}`, dataDoc)
                .put(`receptionist/users/edit/${props?.user_id}`, dataDoc)
                .then((res) => {
                    console.log("res", res?.data)
                    toast.success('Të dhënat u përditësuan me sukses!');
                    // navigate("/listimet")

                })
                .catch((err) => {
                    console.log("axiosErr", err);
                    toast.error('Të dhënat  nuk janë përditësuar!');
                });
        }
    };

    useEffect(() => {
        getCurrentUsers();
    }, []);

    console.log('dataObject', data);

    const changeImage = (e) => {
        // const ud = Object.assign({}, image);
        // ud.photo = e.target.files[0];
        // setImage(ud);
        if (e.target.files && e.target.files[0]) {
            setImage(URL.createObjectURL(e.target.files[0]));
        }
        const ud = Object.assign({}, userData);
        ud.photo = e.target.files[0];
        setUserData(ud);
        console.log("picture", ud);
    };


    console.log("cust", props?.customer_id)
    const saveImage = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("photo", userData?.photo);

        const config = {
            headers: {
                "content-type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
            },
        };

        if (props?.role === 2) {
            axios
                // .put(
                //     `/admin/editCustomerImage/${pid}/${props?.customer_id}`, formData, config
                // )
                .put(
                    `customerUser/users/editCustomerImage/${props?.user_id}`, formData, config
                )
                .then((res) => {
                    // console.log(res.data);
                    toast.success("Foto u ndryshua me sukses!");
                    setImage(null);
                    console.log("response", res)
                    getCurrentUsers();

                })
                .catch((err) => {
                    toast.error("Something went wrong!");
                    console.log(err);
                });
        }
        else if (props?.role === 3) {
            axios
                // .put(
                //     `/admin/editCustomerImage/${pid}/${props?.customer_id}`, formData, config
                // )
                .put(
                    `receptionist/users/editCustomerImage/${props?.user_id}`, formData, config
                )
                .then((res) => {
                    // console.log(res.data);
                    toast.success("Foto u ndryshua me sukses!");
                    setImage(null);
                    console.log("response", res)
                    getCurrentUsers();

                })
                .catch((err) => {
                    toast.error("Something went wrong!");
                    console.log(err);
                });
        }
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();
        const passwordat = {
            oldPass: e?.target.passwordOld.value,
            pass: e?.target.newPassword.value,
            pasiperseritur: e.target.repetPassword.value,
        };
        console.log("testRpet", passwordat.pasiperseritur)
        console.log("testPasssssss", passwordat.pass)
        setPasswordChange(passwordat);
        if (passwordat.pass != passwordat.pasiperseritur) {
            toast.error("Fjaleklimet nuk perputhen")
        } else {

            if (props?.role === 2) {
                axios.put(`customerUser/profile/changePassword`, passwordat)
                    .then((res) => {

                        console.log("pe kqyri", res)
                        if (res.data === "Please enter correct old password") {
                            toast.error(`${res.data}`, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });

                        } else if (res.data !== "Please enter correct old password") {
                            toast.success("Fjalëkalimi juaj ka ndryshuar", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                            // window.location = "/profile";
                            console.log("hahhahah", res)
                        }
                        setDisabledPass(false);
                    })
                    .catch((err) => {
                        console.log("error incoming", err);
                    });
            }
            else if (props?.role === 3) {
                axios.put(`receptionist/profile/changePassword`, passwordat)
                    .then((res) => {

                        console.log("pe kqyri", res)
                        if (res.data === "Please enter correct old password") {
                            toast.error(`${res.data}`, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });

                        } else if (res.data !== "Please enter correct old password") {
                            toast.success("Fjalëkalimi juaj ka ndryshuar ", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                            // window.location = "/profile";
                            console.log("hahhahah", res)
                        }
                        setDisabledPass(false);
                    })
                    .catch((err) => {
                        console.log("error incoming", err);
                    });
            }
            else {
                axios.put(`user/profile/changePassword`, passwordat)
                    .then((res) => {

                        console.log("pe kqyri", res)
                        if (res.data === "Please enter correct old password") {
                            toast.error(`${res.data}`, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });

                        } else if (res.data !== "Please enter correct old password") {
                            toast.success("Fjalëkalimi juaj ka ndryshuar", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                            // window.location = "/profile";
                            console.log("hahhahah", res)
                        }
                        setDisabledPass(false);
                    })
                    .catch((err) => {
                        console.log("error incoming", err);
                    });
            }
        }
    }

    console.log("userData", userData)
    if (loading === true) {
        return <Loader/>
        } else
    return (
        <>

            <div className="ProfileRoli2dhe3" style={{ left: props?.menu ? '21%' : '30px', width: props?.menu ? "76%" : "90%" }}>
                        <h3 className="titujt mt-3">Ndrysho Profilin</h3>
                        <div className="profiliMeRole mt-3">
                    <div className="btnActive">
                        <button className={active === false && 'active'} onClick={() => setActive(false)}>Ndrysho të dhënat </button>
                        <button className={active === true && 'active'} onClick={() => setActive(true)}>Ndrysho fjalëkalimin</button>
                    </div>
                        </div>
                <div className="ProfileRolet2dhe3Static"style={{ left: props?.menu ? '21%' : '38px', width: props?.menu ? "80%" : "100%" }}>
                    {
                        active === false &&

                        <div className="editProfile2dhe3Form">
                            <div className=" profile-image-box-V  text-center">
                                <div className="foto">

                                    <img
                                    src={userData?.photo === "null" ? comapny : (image === null ? `${process.env.REACT_APP_UPLOADS + userData?.photo}`: image)}
                                        alt="Logo e kompanis"
                                        width="200px"
                                    />
                                </div>
                                {/* <input type="file" onChange={changeImage} /> */}

                            </div>

                            {
                                <label
                                    htmlFor="profile-picture-input"
                                    className="profile-picture-editBtn"
                                >
                                    <span className="buttonNdrysho">Ndrysho</span>
                                    <input
                                        type="file"
                                        style={{ cursor: "pointer" }}
                                        id="profile-picture-input"
                                        onChange={changeImage}
                                        className="hidden-hidden"
                                    />
                                </label>
                            }

                            {!!image && <button onClick={saveImage} className="btn changePicBtn" >Ruaj </button>}
                            <form onSubmit={submitData} >

                                <div>
                                    <p className="text-left">Emri</p>
                                    {props?.external_db === true ?
                                        <input
                                            type="text" className="form-control mb-3 not-allowed"
                                            onChange={Name}
                                            value={userData?.Name}
                                            defaultValue={userData?.Name}
                                            placeholder={"Emri"}
                                            disabled
                                        ></input> :
                                        <input
                                            type="text" className="form-control mb-3"
                                            onChange={Name}
                                            value={userData?.Name}
                                            defaultValue={userData?.Name}
                                            placeholder={"Emri"}
                                        ></input>}
                                </div>
                                <div className="mbiemri">
                                    <p className="text-left">Mbiemri</p>
                                    {props?.external_db === true ?
                                        <input
                                            type="text" className="form-control mb-3 not-allowed"
                                            onChange={SureName}
                                            value={userData?.SureName}
                                            defaultValue={userData?.SureName}
                                            placeholder={"Mbiemri"}
                                            disabled
                                        ></input> :
                                        <input
                                            type="text" className="form-control mb-3"
                                            onChange={SureName}
                                            value={userData?.SureName}
                                            defaultValue={userData?.SureName}
                                            placeholder={"Mbiemri"}
                                        ></input>}
                                </div>

                                <div className="specializimi">
                                    <p className="text-left">Specializimi</p>
                                    {props?.external_db === true ?
                                        <input
                                            type="text" className="form-control mb-3 not-allowed"
                                            onChange={specializimi}
                                            value={userData?.specializimi}
                                            defaultValue={userData?.specializimi}
                                            placeholder="Specializimi"
                                            disabled
                                        ></input> :
                                        <input
                                            type="text" className="form-control mb-3"
                                            onChange={specializimi}
                                            value={userData?.specializimi}
                                            defaultValue={userData?.specializimi}
                                            placeholder="Specializimi"
                                        ></input>}
                                </div>
                                <div className="titulli">
                                    <p className="text-left">Titulli</p>
                                    {props?.external_db === true ?
                                        <input
                                            type="text" className="form-control mb-3 not-allowed"
                                            onChange={titulli}
                                            value={userData?.titulli}
                                            defaultValue={userData?.titulli}
                                            placeholder={"Titulli"}
                                            disabled
                                        ></input> :
                                        <input
                                            type="text" className="form-control mb-3"
                                            onChange={titulli}
                                            value={userData?.titulli}
                                            defaultValue={userData?.titulli}
                                            placeholder={"Titulli"}
                                        ></input>}
                                </div>
                                <div className="titulli">
                                    <p className="text-left">email</p>

                                    <input
                                        type="email" className="form-control mb-3 not-allowed"
                                        onChange={email}
                                        value={userData?.email}
                                        defaultValue={userData?.email}
                                        placeholder={"Email"}
                                        disabled
                                    ></input>
                                </div>
                                <div className="bio">
                                    <p className="text-left">Bio</p>
                                    <textarea
                                        type="text" className="form-control mb-3 textareaDesign"
                                        onChange={Bio}
                                        value={userData?.Bio}
                                        defaultValue={userData?.Bio}
                                        placeholder={"Bio"}
                                    ></textarea>
                                </div>
                                <div className="input-design text-left">
                                    <label className="col-md-6 p-0"><p className="">Roli</p></label>
                                    <select className='col-md-6 mb-2 p-2 roletSelect' disabled style={{ opacity: 0.5, cursor: "not-allowed" }} onChange={handleRoleName} required title="This field is required" >
                                        <option defaultvalue={userData?.RoleID} selected disabled>Ju lutem zgjedhni </option>
                                        {rolet?.map((el) => {
                                            console.log("el", el)
                                            return (
                                                <option
                                                    value={el.ID}
                                                    selected={
                                                        Number(userData?.RoleID) ===
                                                            el?.ID
                                                            ? "selected"
                                                            : false
                                                    }
                                                    className="options">
                                                    {el?.Description}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>


                                <div className="input-design text-left">
                                    <label className="col-10 col-md-11 p-0 " for={"NotificationWithSMS"}>
                                        <p>HasUserAccount</p>
                                    </label>
                                    <input type="checkbox" id={"NotificationWithSMS"} disabled className="switch col-md-1 col-3"
                                        style={{ opacity: 0.5, cursor: "not-allowed" }}
                                        value={userData?.HasUserAccount} defaultChecked={userData?.HasUserAccount} />
                                </div>
                                {/* <div className="row">
                                <label for={"TermineCdoMin"} id="kushtet" className="kushtet text-left  col-md-10 mb-1 ml-2 col-7">
                                    <p>roli <span className='font-weight-bold'>{userData?.RoleID}</span> </p>
                                </label>
                                <input
                                    id={"TermineCdoMin"}
                                    className="inputt xona20 col-md-1 col-11"
                                    type="number"
                                    defaultValue={userData?.RoleID}
                                    onChange={handleRoleName}
                                    required
                                />
                            </div> */}

                                <div className="input-design text-left">
                                    <label className=" col-10 col-md-11 p-0 " for={"NotificationWithSMS"}>
                                        <p>E disponueshme online</p>
                                    </label>
                                    <input type="checkbox" id={"NotificationWithSMS"} className="switch col-md-1 col-3" value={userData?.AwailableOnline} defaultChecked={userData?.AwailableOnline} onClick={AwailableOnline} />
                                </div>
                                <div className="input-design text-left">
                                    <label className="col-10 col-md-11 p-0  " for={"NotificationWithSMS"}>
                                        <p>Lejo rezervime nga perdoruesi</p>
                                    </label>
                                    <input type="checkbox" id={"NotificationWithSMS"} className="switch col-md-1 col-3" value={userData?.AllowBookingByUsers} defaultChecked={userData?.AllowBookingByUsers} onClick={AllowBookingByUsers} />
                                </div>
                                <div className="input-design text-left">
                                    <label className="col-10 col-md-11 p-0 " for={"NotificationWithSMS"}>
                                        <p>Aktiv</p>
                                    </label>
                                    <input type="checkbox" id={"NotificationWithSMS"} className="switch col-md-1 col-3" value={userData?.isActive} defaultChecked={userData?.isActive} onClick={isActive} />
                                </div>
                                <div className="input-design text-left">


                                    <label for={"TermineCdoMin"} id="kushtet" className="kushtet  col-md-11 mb-1 col-10 p-0 ">
                                        <p>Shfaq terminet çdo <span className='font-weight-bold'>{userData?.DefaultDuration}</span> minuta</p>
                                    </label>
                                    <input
                                        id={"TermineCdoMin"}
                                        className="inputt xona20 col-md-1 col-2"
                                        type="number"
                                        defaultValue={userData?.DefaultDuration}
                                        onChange={DefaultDuration}
                                        required
                                    />

                                </div>



                                <Link className="linkOrari" to={"/orariPunes/" + props?.user_id}>Orari i Punës</Link>

                                <div className="form-group">
                                    <button type="submit" className="form-control  btn btnRuaj submit">Ruaj</button>
                                </div>
                            </form>
                        </div >}
                    {active === true &&
                        <div className="editRolet2dhe3Pass" >
                            <form onSubmit={handleChangePassword}>
                                <div className="form-input">
                                    <h4 className="text-left mb-4">Ndrysho Fjalëkalimi!</h4>
                                    <label htmlFor="phone" id="kushtet" className="kushtet text-left">
                                        <span>Fjalëkalimi juaj i vjeter</span>
                                        <input
                                            onChange={password}
                                            placeholder="Fjalëkalimi juaj i vjeter"
                                            type={!show ? "password" : "text"}
                                            id="passwordOld"
                                            className="form-control2" 
                                            />
                                    </label>
                                              <span
                                            toggle="#password-field"
                                             className={show ? "fa fa-fw fa-eye field-icon toggle-password" : "fa fa-fw fa-eye-slash field-icon toggle-password"}
                                          onClick={hidePassword} />
                                    <label htmlFor="phone" id="kushtet" className="kushtet text-left">
                                        <span> Fjalëkalimi juaj i ri</span>
                                        <input
                                             type={!show ? "password" : "text"}
                                            placeholder="Fjalëkalimi juaj i ri"
                                            name="newPassword"
                                            id="newPassword"
                                            className="form-control2" />
                                    </label>
                                              <span
                                            toggle="#password-field"
                                             className={show ? "fa fa-fw fa-eye field-icon toggle-password" : "fa fa-fw fa-eye-slash field-icon toggle-password"}
                                          onClick={hidePassword} />
                                    <label htmlFor="phone" id="kushtet" className="kushtet text-left">
                                        <span>Perseritni fjalëkalimin tuaj të ri</span>
                                        <input
                                             type={!show ? "password" : "text"}
                                            placeholder="Perseritni fjalëkalimin tuaj"
                                            name="repetPassword"
                                            id="repetPassword"
                                            className="form-control2" />
                                    </label>
                                              <span
                                            toggle="#password-field"
                                             className={show ? "fa fa-fw fa-eye field-icon toggle-password" : "fa fa-fw fa-eye-slash field-icon toggle-password"}
                                          onClick={hidePassword} />
                                </div>
                                <div className="form-group">
                                    <button type="submit" disabled={!disabledPass} className="form-control  btn btnLogin submit">Ruaj</button>
                                </div>

                            </form>
                        </div>

                    }
                </div>
            </div >

        </>
    )


}


const mapStateToProps = state => {
    return {
        lang: state.data.lang,
        isLoading: state.data.isLoading,
        loggedIn: state.data.loggedIn,
        menu: state.data.menu,
        user_id: state.data.user_id,
        customer_id: state.data.customer_id,
        external_db: state.data.external_db,
        role: state.data.role,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        loggMeIN: () => dispatch(loggMeIN()),
        rmAuth: () => dispatch(rmAuth()),
        toggleMenu: () => dispatch(toggleMenu()),
        // loadingOff: () => dispatch(loadingOff()),
        setCustomerId: (customer_id) => dispatch(setCustomerId(customer_id)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditDoktorat)