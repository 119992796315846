import axios from "../../../axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./editDoktorat.scss";
// import "./shtoKartelen.scss"
import { useNavigate, useParams } from "react-router";
import { connect } from 'react-redux';
import {
    loggMeIN,
    rmAuth,
    toggleMenu,
    setCustomerId,
    setExternalDB
} from "../../../redux/Functions/actions";
import { Link } from "react-router-dom";
import userProfile from "../../../images/defaultImage.jpg"
import Loader from "../Loader/Loader";





function EditDoktorat(props) {
    console.log('po hapna', props);

    const [data, setData] = useState();
    const [addClinic, setAddClinic] = useState([]);
    const [cardNumber, setCardNumber] = useState(props?.getCardNumber);
    const [userData, setUserData] = useState([]);
    const [image, setImage] = useState(null);
    const [rolet, setRolet] = useState();
    const [roli, setRoli] = useState();
    const [loading, setLoading] = useState(true);



    const navigate = useNavigate();
    const { pid } = useParams();
    console.log("Pid", pid)

    const getCurrentUsers = () => {

        axios
            // .get(`/admin/getCustomerUser/${pid}/${props?.customer_id}`)
            .get(`customer/users/single/${pid}`)
            .then((data) => {
                setUserData(data?.data[0]);
                setLoading(false);

                console.log("userdata", data?.data[0]);
                // setisloading(true);
            })
            .catch((err) => {
                toast.error("Something went wrong!");
                console.log(err);
            });
    };
    const getRole = () => {
        axios
            // .get(`/admin/getCustomerUser/${pid}/${props?.customer_id}`)
            .get(`customer/appointments/getCostumerUserRoles`)
            .then((data) => {
                setRolet(data?.data);
                console.log("userrrrrrrrrrrrrrrrr", data?.data);
                // setisloading(true);
            })
            .catch((err) => {
                toast.error("Something went wrong!");
                console.log(err);
            });
    };

    useEffect(() => {
        getRole();
    }, []);

    const Name = (e) => {
        const ud = Object.assign({}, userData);
        ud.Name = e.target.value;
        setUserData(ud);


    };
    const SureName = (e) => {
        const ud = Object.assign({}, userData);
        ud.SureName = e.target.value;
        setUserData(ud);


    };


    const specializimi = (e) => {
        const ud = Object.assign({}, userData);
        ud.specializimi = e.target.value;
        setUserData(ud);
        console.log("Specializimi", e.target.value)
    };

    const titulli = (e) => {
        const ud = Object.assign({}, userData);
        ud.titulli = e.target.value;
        setUserData(ud);

    };
    const Bio = (e) => {
        const ud = Object.assign({}, userData);
        ud.Bio = e.target.value;
        setUserData(ud);

    }
    const AwailableOnline = (e) => {
        const ud = Object.assign({}, userData);
        ud.AwailableOnline = e.currentTarget.checked;
        setUserData(ud);

    }
    const AllowBookingByUsers = (e) => {
        const ud = Object.assign({}, userData);
        ud.AllowBookingByUsers = e.currentTarget.checked;
        setUserData(ud);

    };
    const BookingOnMonday = (e) => {
        const ud = Object.assign({}, userData);
        ud.BookingOnMonday = e.currentTarget.checked;
        setUserData(ud);

    };
    const BookingOnTuesday = (e) => {
        const ud = Object.assign({}, userData);
        ud.BookingOnTuesday = e.currentTarget.checked;
        setUserData(ud);

    };
    const BookingOnWednesday = (e) => {
        const ud = Object.assign({}, userData);
        ud.BookingOnWednesday = e.currentTarget.checked;
        setUserData(ud);

    };
    const BookingOnThursday = (e) => {
        const ud = Object.assign({}, userData);
        ud.BookingOnThursday = e.currentTarget.checked;
        setUserData(ud);

    };
    const BookingOnFriday = (e) => {
        const ud = Object.assign({}, userData);
        ud.BookingOnFriday = e.currentTarget.checked;
        setUserData(ud);

    };

    const BookingOnSaturday = (e) => {
        const ud = Object.assign({}, userData);
        ud.BookingOnSaturday = e.currentTarget.checked;
        setUserData(ud);

    };
    const BookingOnSunday = (e) => {
        const ud = Object.assign({}, userData);
        ud.BookingOnSunday = e.currentTarget.checked;
        setUserData(ud);

    };

    const isActive = (e) => {
        const ud = Object.assign({}, userData);
        ud.isActive = e.currentTarget.checked;
        setUserData(ud);

    };
    const HasUserAccount = (e) => {
        const ud = Object.assign({}, userData);
        ud.HasUserAccount = e.currentTarget.checked;
        setUserData(ud);

    };
    const email = (e) => {
        const ud = Object.assign({}, userData);
        ud.email = e.target.value;
        setUserData(ud);
    };
    const DefaultDuration = (e) => {
        const ud = Object.assign({}, userData);
        ud.DefaultDuration = e.target.value;
        setUserData(ud);
    };
    const handleRoleName = (e) => {
        const ud = Object.assign({}, userData);
        ud.RoleID = e.target.value;
        setUserData(ud);
    };
 


    const submitData = (e) => {
        e.preventDefault();
        let dataDoc = {
            AllowBookingByUsers: userData?.AllowBookingByUsers === true ? 1 : 0,
            AwailableOnline: userData?.AwailableOnline === true ? 1 : 0,
            BookingOnMonday: userData?.BookingOnMonday === true ? 1 : 0,
            BookingOnSaturday: userData?.BookingOnSaturday === true ? 1 : 0,
            BookingOnSunday: userData?.BookingOnSunday === true ? 1 : 0,
            BookingOnThursday: userData?.BookingOnThursday === true ? 1 : 0,
            BookingOnTuesday: userData?.BookingOnTuesday === true ? 1 : 0,
            BookingOnWednesday: userData?.BookingOnWednesday === true ? 1 : 0,
            BookingOnFriday: userData?.BookingOnFriday === true ? 1 : 0,
            isActive: userData?.isActive === true ? 1 : 0,
            Name: userData.Name,
            SureName: userData.SureName,
            photo: userData.photo,
            specializimi: userData.specializimi,
            titulli: userData.titulli,
            Bio: userData.Bio,
            HasUserAccount: userData?.HasUserAccount === true ? 1 : 0,
            email: userData?.email,
            RoleID: Number(userData?.RoleID),
            UserID: props?.user_id,
            DefaultDuration: userData?.DefaultDuration
        }
        axios
            // .put(`/admin/editCustomerUser/${pid}/${props?.customer_id}`, dataDoc)
            .put(`customer/users/edit/${pid}`, dataDoc)
            .then((res) => {
                console.log("res", res?.data)
                toast.success('Të dhënat u përditësuan me sukses!');
                navigate("/listimet")

            })
            .catch((err) => {
                console.log("axiosErr", err);
                toast.error('Të dhënat  nuk janë përditësuar!');
            });
    };

    useEffect(() => {
        getCurrentUsers();
    }, []);
    console.log('dataObject', data);


    const changeImage = (e) => {
        // const ud = Object.assign({}, image);
        // ud.photo = e.target.files[0];
        // setImage(ud);
        if (e.target.files && e.target.files[0]) {
            setImage(URL.createObjectURL(e.target.files[0]));
        }
        const ud = Object.assign({}, userData);
        ud.photo = e.target.files[0];
        setUserData(ud);
        console.log("picture", ud);
    };

    console.log("cust", props?.customer_id)
    const saveImage = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("photo", userData?.photo);

        const config = {
            headers: {
                "content-type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
            },
        };

        axios
            // .put(
            //     `/admin/editCustomerImage/${pid}/${props?.customer_id}`, formData, config
            // )
            .put(
                `customer/users/editCustomerImage/${pid}`, formData, config
            )
            .then((res) => {
                // console.log(res.data);
                toast.success("Foto u ndryshua me sukses!");
                setImage(null);
                console.log("response", res)
                getCurrentUsers();

            })
            .catch((err) => {
                toast.error("Something went wrong!");
                console.log(err);
            });
    };
    console.log("userData", userData)
    if (loading === true) {
        return <Loader/>
        } else
    return (
        <>
            <div className="testing">
                <div className="">
                    <i className="goBack" aria-hidden="true" onClick={() => navigate(-1)} style={{ left: props?.menu ? '23%' : '6%' }} />
                </div>
            </div>
            <div className="EditProfileDoktorat" style={{ left: props?.menu ? '20%' : '0', width: props?.menu ? "70%" : "80%" }}>
                <h3 className="titujt">Ndrysho Profilin</h3>
                <div className="EditModalStatic" style={{ left: props?.menu ? '10%' : '0', width: props?.menu ? "70%" : "80%" }}>
                    <div className="editForm">
                        <div className=" profile-image-box-V  text-center">
                            <div className="foto mb-1">

                                <img
                                    src={userData?.photo === null || userData?.photo === "null"  ? userProfile : (image === null ? `${process.env.REACT_APP_UPLOADS + userData?.photo}`: image)}
                                    alt="Logo e kompanis"
                                    width="200px"
                                />
                            </div>
                            {/* <input type="file" onChange={changeImage} /> */}

                        </div>

                        {
                            <label
                                htmlFor="profile-picture-input"
                                className="profile-picture-editBtn"
                            >
                                <span className="buttonNdrysho">Ndrysho</span>
                                <input
                                    type="file"
                                    style={{ cursor: "pointer" }}
                                    id="profile-picture-input"
                                    onChange={changeImage}
                                    className="hidden-hidden"
                                />
                            </label>
                        }

                        {!!image && <button onClick={saveImage} className=" profile-picture-editBtn mt-3" >Ruaj </button>}
                        <form onSubmit={submitData} >

                            <div>
                                <p className="text-left">Emri</p>
                                {props?.external_db === true ?
                                    <input
                                        type="text" className="form-control mb-3 not-allowed"
                                        onChange={Name}
                                        value={userData?.Name}
                                        defaultValue={userData?.Name}
                                        placeholder={"Emri"}
                                        disabled
                                    ></input> :
                                    <input
                                        type="text" className="form-control mb-3"
                                        onChange={Name}
                                        value={userData?.Name}
                                        defaultValue={userData?.Name}
                                        placeholder={"Emri"}
                                    ></input>}
                            </div>
                            <div className="mbiemri">
                                <p className="text-left">Mbiemri</p>
                                {props?.external_db === true ?
                                    <input
                                        type="text" className="form-control mb-3 not-allowed"
                                        onChange={SureName}
                                        value={userData?.SureName}
                                        defaultValue={userData?.SureName}
                                        placeholder={"Mbiemri"}
                                        disabled
                                    ></input> :
                                    <input
                                        type="text" className="form-control mb-3"
                                        onChange={SureName}
                                        value={userData?.SureName}
                                        defaultValue={userData?.SureName}
                                        placeholder={"Mbiemri"}
                                    ></input>}
                            </div>
                            <div className="specializimi">
                                <p className="text-left">Specializimi</p>
                                {props?.external_db === true ?
                                    <input
                                        type="text" className="form-control mb-3 not-allowed"
                                        onChange={specializimi}
                                        value={userData?.specializimi}
                                        defaultValue={userData?.specializimi}
                                        placeholder="Specializimi"
                                        disabled
                                    ></input> :
                                    <input
                                        type="text" className="form-control mb-3"
                                        onChange={specializimi}
                                        value={userData?.specializimi}
                                        defaultValue={userData?.specializimi}
                                        placeholder="Specializimi"
                                    ></input>}
                            </div>
                            <div className="titulli">
                                <p className="text-left">Titulli</p>
                                {props?.external_db === true ?
                                    <input
                                        type="text" className="form-control mb-3 not-allowed"
                                        onChange={titulli}
                                        value={userData.titulli}
                                        defaultValue={userData?.titulli}
                                        placeholder={"Titulli"}
                                        disabled
                                    ></input> : <input
                                        type="text" className="form-control mb-3"
                                        onChange={titulli}
                                        value={userData.titulli}
                                        defaultValue={userData?.titulli}
                                        placeholder={"Titulli"}
                                    ></input>}
                            </div>
                            <div className="titulli">
                                <p className="text-left">email</p>

                                <input
                                    type="email" className="form-control mb-3 not-allowed"
                                    onChange={email}
                                    value={userData.email}
                                    defaultValue={userData?.email}
                                    placeholder={"Email"}
                                    disabled
                                ></input>
                            </div>
                            <div className="bio">
                                <p className="text-left">Bio</p>
                                <textarea
                                    type="text" className="form-control mb-3 textareaDesign"
                                    onChange={Bio}
                                    value={userData?.Bio}
                                    defaultValue={userData?.Bio == "undefined" ? " " : userData?.Bio}
                                    placeholder={"Bio"}
                                ></textarea>
                            </div>
                            <div className="input-design text-left">
                                <label className="col-md-6 p-0"><p className="">Roli</p></label>

                                <select className='col-md-6 mb-2 p-2 roletSelect' onChange={handleRoleName} required title="This field is required" >
                                    <option defaultvalue={userData?.RoleID} selected disabled>Ju lutem zgjedhni </option>
                                    {rolet?.map((el) => {
                                        console.log("el", el)
                                        return (
                                            <option
                                                value={el.ID}
                                                selected={
                                                    Number(userData?.RoleID) ===
                                                        el?.ID
                                                        ? "selected"
                                                        : false
                                                }
                                                className="options">
                                                {el?.Description}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>


                            <div className="input-design text-left">
                                <label className="col-10 col-md-11 p-0 " for={"NotificationWithSMS"}>
                                    <p>HasUserAccount</p>
                                </label>
                                <input type="checkbox" id={"NotificationWithSMS"} className="switch col-md-1 col-3" value={userData?.HasUserAccount} defaultChecked={userData?.HasUserAccount} onClick={HasUserAccount} />
                            </div>
                            {/* <div className="row">
                                <label for={"TermineCdoMin"} id="kushtet" className="kushtet text-left  col-md-10 mb-1 ml-2 col-7">
                                    <p>roli <span className='font-weight-bold'>{userData?.RoleID}</span> </p>
                                </label>
                                <input
                                    id={"TermineCdoMin"}
                                    className="inputt xona20 col-md-1 col-11"
                                    type="number"
                                    defaultValue={userData?.RoleID}
                                    onChange={handleRoleName}
                                    required
                                />
                            </div> */}

                            <div className="input-design text-left">
                                <label className=" col-10 col-md-11 p-0 " for={"NotificationWithSMS"}>
                                    <p>E disponueshme online</p>
                                </label>
                                <input type="checkbox" id={"NotificationWithSMS"} className="switch col-md-1 col-3" value={userData?.AwailableOnline} defaultChecked={userData?.AwailableOnline} onClick={AwailableOnline} />
                            </div>
                            <div className="input-design text-left">
                                <label className="col-10 col-md-11 p-0  " for={"NotificationWithSMS"}>
                                    <p>Lejo rezervime nga perdoruesi</p>
                                </label>
                                <input type="checkbox" id={"NotificationWithSMS"} className="switch col-md-1 col-3" value={userData?.AllowBookingByUsers} defaultChecked={userData?.AllowBookingByUsers} onClick={AllowBookingByUsers} />
                            </div>
                            <div className="input-design text-left">
                                <label className="col-10 col-md-11 p-0 " for={"NotificationWithSMS"}>
                                    <p>Aktiv</p>
                                </label>
                                <input type="checkbox" id={"NotificationWithSMS"} className="switch col-md-1 col-3" value={userData?.isActive} defaultChecked={userData?.isActive} onClick={isActive} />
                            </div>
                            <div className="input-design text-left">
                                <label for={"TermineCdoMin"} id="kushtet" className="kushtet  col-md-11 mb-1 col-12 p-0 ">
                                    <p>Shfaq terminet çdo <span className='font-weight-bold'>{userData?.DefaultDuration}</span> minuta</p>
                                </label>
                                <input
                                    id={"TermineCdoMin"}
                                    className="inputt xona20 col-md-1 col-12"
                                    type="number"
                                    defaultValue={userData?.DefaultDuration}
                                    onChange={DefaultDuration}
                                    required
                                />
                            </div>




                            <Link className="linkOrari" to={"/orariPunes/" + pid}>Orari i Punës</Link>

                            <div className="form-group">
                                <button type="submit" className="form-control  btn btnRuaj submit">Ruaj</button>
                            </div>
                        </form>
                    </div >

                </div>
            </div >

        </>
    )


}


const mapStateToProps = state => {
    return {
        lang: state.data.lang,
        isLoading: state.data.isLoading,
        loggedIn: state.data.loggedIn,
        menu: state.data.menu,
        user_id: state.data.user_id,
        customer_id: state.data.customer_id,
        external_db: state.data.external_db,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        loggMeIN: () => dispatch(loggMeIN()),
        rmAuth: () => dispatch(rmAuth()),
        toggleMenu: () => dispatch(toggleMenu()),
        // loadingOff: () => dispatch(loadingOff()),
        setCustomerId: (customer_id) => dispatch(setCustomerId(customer_id)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditDoktorat)