import React, { useEffect, useState } from "react";
import './stafiKompanise.scss';
import axios from '../../../axios';
import { connect } from "react-redux";
import {
    loggMeIN,
    rmAuth,
    toggleMenu
} from "./../../../redux/Functions/actions";
import Loader from "../Loader/Loader";
import ReactPaginate from "react-paginate";
import { useParams, useNavigate } from "react-router";
import "./stafiKompanise.scss"
import Search from "../Search/search";
import userProfile from "../../../images/defaultImage.jpg"

const Stafi = (props) => {
    const { name } = useParams();

    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [doctors, setDoctors] = useState();
    const [filtered, setFiltered] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [open, setOpen] = useState(false);
    const productsPerPage = 20;
    const pagesVisited = pageNumber * productsPerPage;
    let displayFilteredData = [];
    const pageCount = Math.ceil(filtered.length / productsPerPage);
    const params = useParams();

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const getDoctors = () => {
        axios
            .get(`user/terminet/getDoctorsByPatient/${params.cid}`)
            .then((response) => {
                console.log("setDoctors", response.data);
                setDoctors(response.data);
                setLoading(false);
                setFiltered(response.data)
            })
            .catch((err) => console.log("error", err));
    };

    useEffect(() => {
        getDoctors();
    }, []);


    if (loading === true) {
        return <Loader/>
        } else
        return (
            <>
                <div className="testing">
                    <div className="">
                        <i className="goBack" aria-hidden="true" onClick={() => navigate(-1)} style={{ left: props?.menu ? '23%' : '6%' }} />
                    </div>
                    <div className="">
                        <Search data={doctors} filtered={setFiltered} setPageNumber={setPageNumber} />
                    </div>
                </div>
                <div className="stafiKompaniseContent">
                    <div className="stafiKompanise" style={{ left: props?.menu ? '20%' : '0', width: props?.menu ? "80%" : "100%" }}>
                        <h3 class="titujt">Stafi i {name}</h3>
                        <div className="stafiPosition" style={{ left: props?.menu ? '20%' : '0%' }}>
                            <div className="container-fluid">
                                <section id='ourTeam' className='ourTeam' style={{ left: props?.menu ? '20%' : '0', width: props?.menu ? "95%" : "100%", margin: props?.menu ? "20px" : "0 auto" }}>
                                    <div className='row'>
                                        {displayFilteredData = filtered?.slice
                                            (pagesVisited, pagesVisited + productsPerPage)?.map
                                            (filtered => {
                                                return (<>
                                                    {filtered.length === 0 ? " " 
                                                    :
                                                    <div className='memberListing col-xl-3 col-lg-4 col-sm-6 col-12' onClick={() => { navigate(`/doctorSingle/${params.cid}/${filtered?.id}`) }}>
                                                        <div className='memberImage'>   
                                                         <img
                                                            src={filtered?.photo === null || filtered?.photo === "null" ? userProfile : `${process.env.REACT_APP_UPLOADS + filtered?.photo}`}
                                                            alt="Logo e kompanis"
                                                        /></div>
                                                        <div className='memberContent'>
                                                            <h4>{filtered?.titulli} {filtered?.Name} {filtered?.SureName}</h4>
                                                            <h6>{filtered?.specializimi}</h6>
                                                        </div>
                                                    </div>
                                                    }
                                                </>
                                                )
                                            })}
                                    </div>
                                </section>
                            </div>
                            <div className="paginationnn m-5">
                                <div className="paginationWrapperPatien text-right">
                                    {(doctors?.length >= productsPerPage) ?
                                        <ReactPaginate
                                            previousLabel={<i className='fa fa-angle-double-left' />}
                                            nextLabel={<i className='fa fa-angle-double-right' />}
                                            pageCount={pageCount}
                                            onPageChange={changePage}
                                            containerClassName={"paginationBttns"}
                                            previousLinkClassName={"previousBttn"}
                                            nextLinkClassName={"nextBttn"}
                                            disabledClassName={"paginationDisabled"}
                                            activeClassName={"paginationActive"}
                                        /> : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
}
const mapStateToProps = state => {
    return {
        lang: state.data.lang,
        isLoading: state.data.isLoading,
        loggedIn: state.data.loggedIn,
        menu: state.data.menu,
        user_id: state.data.user_id
    }
}
const mapDispatchToProps = dispatch => {
    return {
        loggMeIN: () => dispatch(loggMeIN()),
        rmAuth: () => dispatch(rmAuth()),
        toggleMenu: () => dispatch(toggleMenu())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Stafi);