import React, { useEffect, useState } from "react";
import './kompanite.scss';
import axios from '../../../axios';
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import {
  loggMeIN,
  rmAuth,
  toggleMenu
} from "./../../../redux/Functions/actions";
import { useNavigate, useParams } from "react-router";
import Search from "../Search/search";
import Loader from "../Loader/Loader";

const Kompanite = (props) => {

  const { Pershkrimi } = useParams();
  const { cid } = useParams();

  const [loading, setLoading] = useState(true);
  const [filtered, setFiltered] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [data, setData] = useState([]);
  const productsPerPage = 20;
  const productsPerList = 50;
  const pagesVisited = pageNumber * productsPerPage;
  let displayFilteredData = [];
  const pageCount = Math.ceil(filtered.length / productsPerPage);



  const navigate = useNavigate();
  const getData = () => {
    axios.get(`user/terminet/getCategory/${cid}`)
      .then((response) => {
        console.log("Clinics123", response.data);
        setLoading(false);
        setData(response.data);
        setFiltered(response.data)

      })
      .catch((err) => console.log("error", err));
  };
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    getData();
  }, []);


  if (loading === true) {
    return <Loader />
  } else
    return (
      <>
        <div className="testing">
          <div className="">
            <i className="goBack" aria-hidden="true" onClick={() => navigate(-1)} style={{ left: props?.menu ? '23%' : '6%' }} />
          </div>
          <div className="">
            <Search data={data} filtered={setFiltered} setPageNumber={setPageNumber} />
          </div>
        </div>
        <div className='kompanite ' style={{ left: props?.menu ? '20%' : '0', width: props?.menu ? "80%" : "100%" }}>
          <h3 class="titujt">{Pershkrimi}</h3>
          <div className="kompatniteContainer " style={{ left: props?.menu ? '20%' : '0', width: props?.menu ? "80%" : "100%" }}>
            {filtered?.length === 0 
            ? <h6 className="titujt mt-4">Nuk ka te dhëna</h6> 
            :
              <div className="products mt-5">
                <div className="row productsRow">
                  {displayFilteredData = filtered?.slice
                    (pagesVisited, pagesVisited + productsPerPage)?.map
                    (filtered => {
                      return (<>
                        <div className='productListing'
                          style={{ backgroundImage: `url(${process.env.REACT_APP_UPLOADS + filtered?.llogo})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
                          onClick={() => { navigate(`/stafiiKompanise/${filtered?.customerName}/${filtered?.id}`) }
                          }
                        >
                          < div className='productContent' >
                            {filtered?.llogo === "null" ? <h4 className='productName w-100'>{filtered?.customerName}</h4> : " "}
                            <div className="kompaniteDisplay">
                              <h4 className='productName w-100'>{filtered?.customerName}</h4>
                              <div className='productCapacity w-100'>
                                <i class="fa fa-map-marker pr-2 pe-2" aria-hidden="true" />{filtered?.Adresa}
                              </div>
                              <div className='productCapacityValue'>
                                <i class="fa fa-phone pr-1 pe-2" aria-hidden="true" /> {filtered?.Telefoni}
                              </div>
                              <div className='productCapacity w-100'>
                                <i class="fa fa-envelope pr-1 pe-2" aria-hidden="true"></i>{filtered?.eMail}
                              </div>
                              <div className='productCapacity w-100'>
                                <a className="clinicLinks pr-1 pe-2 " href={"//" + filtered?.webSajti} target="_blank">
                                  {filtered?.webSajti == "" ? " " : <div className="icona">
                                    <i class='fa fa-globe pt-1 pr-1 pe-2' />{filtered?.webSajti}
                                  </div>}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                      )
                    })}
                </div>
                <div className="">
                  <div className=" col-12 paginationn">
                    <div className="paginationWrapperPatien text-right">
                      {(data?.length >= productsPerPage) ?
                        <ReactPaginate
                          previousLabel={<i className='fa fa-angle-double-left' />}
                          nextLabel={<i className='fa fa-angle-double-right' />}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"paginationBttns"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"paginationDisabled"}
                          activeClassName={"paginationActive"}
                        /> : ''
                      }
                    </div>
                  </div>
                </div>
              </div>
            } </div>
        </div>
      </>
    )
}
const mapStateToProps = state => {
  return {
    lang: state.data.lang,
    isLoading: state.data.isLoading,
    loggedIn: state.data.loggedIn,
    menu: state.data.menu,
    user_id: state.data.user_id
  }
}
const mapDispatchToProps = dispatch => {
  return {
    loggMeIN: () => dispatch(loggMeIN()),
    rmAuth: () => dispatch(rmAuth()),
    toggleMenu: () => dispatch(toggleMenu())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Kompanite);
