import React, { useState, useEffect, useRef } from "react";
import './login.scss';
import Logo from '../../images/logo-v2-centered.svg';
import { toast } from "react-toastify";
import axios from "../../axios";
import jwt_decode from "jwt-decode";
import { connect } from "react-redux";
import {
    loggMeIN,
    setAuth,
    setUserId,
    setRole,
    setCustomerId,
    setExternalDB
} from "../../redux/Functions/actions";
const Login = (props) => {

    const [userData, adduserdata] = useState({});
    const [errors, addErrors] = useState('');
    const [emailFocus, setEmailFocus] = useState(false);
    const [show, setShow] = useState(false);

    const hidePassword = () => {
        setShow(!show);
    }

    const d1 = new Date().getDate();
    const d = new Date();
    const monthNames = [
        "Janar",
        "Shkurt",
        "Mars",
        "Prill",
        "Maj",
        "Qershor",
        "Korrik",
        "Gusht",
        "Shtator",
        "Tetor",
        "Nentor",
        "Dhjetor",
    ];

    const username = e => {
        const ud = Object.assign({}, userData);
        ud.email = e.target.value;
        console.log('', e.target.value)
        adduserdata(ud);
    }

    const password = e => {
        const ud = Object.assign({}, userData);
        ud.pass = e.target.value;
        adduserdata(ud);
    }

    const handeleSubmit = (e) => {
        e.preventDefault();
        axios.post('auth/login', userData)
            .then((res) => {
                console.log('res.data.token', res.data.token);
                if (res.data.error === "User does not exist") {
                    toast.error(
                        "Përdoruesi nuk egziston"
                    );
                    setEmailFocus(true);
                }
                else if (res.data.error === "Authentication Failed!") {
                    toast.error(
                        "Fjalëkalimi nuk është valid"
                    );
                    setEmailFocus(true);
                }
                else if (res.data.error == "This user has been deactivated!") {
                    toast.error(
                        "diqka"
                    );
                    setEmailFocus(true);
                }
                else if (res.data.token) {
                    window.localStorage.setItem('terminettoken', res.data.token);
                    setTimeout(() => {
                        let token = window.localStorage.getItem('terminettoken')
                        if (token !== null) {
                            props.setAuth(token);
                            props.loggMeIN();
                            var decoded = jwt_decode(token);

                            console.log('decoded', decoded);
                            props.setRole(decoded.role);
                            props.setCustomerId(decoded.customer_id);
                            props.setExternalDB(decoded.ExternalDB);
                            console.log('customerId', decoded.customer_id);

                            props.setUserId(decoded.id);
                            //    props.setUserId(decoded.id);
                            window.location.reload();
                        }
                    }, 300)

                } else {
                    addErrors(res.data.error);
                }


            })
            .catch(err => {
                console.log('error incoming', err);
            })

    }

    const autoFocusref = useRef(null);
    useEffect(() => {
        autoFocusref.current && autoFocusref.current.focus();
        setEmailFocus(false);
    }, [emailFocus]);

    return (
        <>
            <div class="ola">
                <p className="demo">{d1}</p>
                <div><p className="demo2">{(new Date().getFullYear())}</p></div>
                <p className="demo7">{monthNames[d.getMonth()].slice(0, 3)}</p>
            </div>
            <div className='login'>
                <img src={Logo} alt='logo' width='360px' className="pt-5 pb-2 ml-3 foto" />
                <div className='login-wraper'>
                    <form onSubmit={handeleSubmit}>
                        <input type='email' placeholder='E-mail' ref={autoFocusref} onChange={username}></input>
                        <input id="password-field"
                            type={!show ? "password" : "text"}
                            placeholder='Password' onChange={password}>
                        </input>
                        <span
                            toggle="#password-field"
                            className={show ? "fa fa-fw fa-eye field-icon toggle-password" : "fa fa-fw fa-eye-slash field-icon toggle-password"}
                            onClick={hidePassword} />
                        <div className="form-group mb-3 d-flex justify-content-start">
                            <a href="/forgotPassword" className='pe-2'>Keni harruar fjalëkalimin?</a>
                        </div>
                        <button type='submit'>Login</button>
                        <div className="form-group d-md-flex justify-content-center">
                            <p className='pr-2'>Nuk keni llogari? </p>
                            <a href="/register">Regjistrohu</a>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = state => {
    return {
        lang: state.data.lang,
        isLoading: state.data.isLoading,
        loggedIn: state.data.loggedIn,
        auth: state.data.auth,
        external_db: state.data.external_db,
        customer_id: state.data.customer_id,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        loggMeIN: () => dispatch(loggMeIN()),
        setAuth: (auth) => dispatch(setAuth(auth)),
        setRole: (role) => dispatch(setRole(role)),
        setUserId: (id) => dispatch(setUserId(id)),
        setExternalDB: (external) => dispatch(setExternalDB(external)),
        setCustomerId: (customer_id) => dispatch(setCustomerId(customer_id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);