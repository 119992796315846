import React from 'react'
import './rikthefjalkalimin.scss'
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import { useState, useEffect } from 'react';

const RegisterSuccess = () => {
    const navigate = useNavigate();
        return (
            <>
                <div className="container">
                        <div className="riktheFjalkalimin">
                            <div className='riktheMsg'>
                                <h3 className="mb-5">Rikthe fjalëkalimin</h3>
                                <h5 className="text-center mb-5"> Ju do të pranoni një email për rikthim të fjalëkalimit</h5>
                                <button type="submit" className="button_riktheFjalkalimin" onClick={() => navigate("/")}>
                                    Kyçu
                                </button>
                            </div>
                        </div>
                    </div>
            </>
        )
}
export default RegisterSuccess