import React, { useState, useEffect, useRef } from "react";
import "./companyProfile.scss";
import doctorImage from "../../../images/bl_harrisia.png";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import axios from "../../../axios";
import {
    loggMeIN,
    rmAuth,
    toggleMenu,
    setCustomerId,
    setExternalDB
} from "../../../redux/Functions/actions";
import Loader from "../Loader/Loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import jwtDecode from "jwt-decode";
import comapny from "../../../images/building.jpg"
// import sign from "jwt-encode";
// import jwt_decode from "jwt-decode"



const KompanyProfile = (props) => {
    console.log('sha', props)
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [gender, setGender] = useState(null);
    const [profesionalInfo, setProfessionalInfo] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [image, setImage] = useState(null);
    const [buttonChange, setButtonChange] = useState(true);


    console.log("Propss", props)
    console.log("customerrIDDDDD", props?.customer_id)
    const [show, setShow] = useState(false);


    // const [register, setUserData] = useState([]);
    // const [birthday, setBirthday] = useState(null);
    const [birthday, setBirthday] = useState(new Date());
    // const navigate = useNavigate();



    const getUserData = () => {
        // axios.get(`/admin/getCustomerDetail/${props?.customer_id}`).then((data) => {
        axios.get(`/customer/getCustomerDetail`).then((data) => {
            let incoming = data.data;
            // const ud = Object.assign({}, incoming);
            setUserData(data?.data[0]);
            setLoading(false);
            console.log("userData", data?.data[0])
        }).catch((err) => {
            console.log(err)
        })

    };

    useEffect(() => {
        getUserData();
    }, []);


    const name = (e) => {
        const ud = Object.assign({}, userData);
        ud.DisplayName = e.target.value;
        setUserData(ud);
        setDisabled(true);

    };
    const surname = (e) => {
        const ud = Object.assign({}, userData);
        ud.surename = e.target.value;
        setUserData(ud);
        setDisabled(true);

    };
    const webSajti = (e) => {
        const ud = Object.assign({}, userData);
        ud.webSajti = e.target.value;
        setUserData(ud);
        setDisabled(true);

    };

    const adresa = (e) => {
        const ud = Object.assign({}, userData);
        ud.Adresa = e.target.value;
        setUserData(ud);
        setDisabled(true);

    };

    const email = (e) => {
        const ud = Object.assign({}, userData);
        ud.eMail = e.target.value;
        setUserData(ud);
        setDisabled(true);


    };

    const telefoni = (e) => {
        const ud = Object.assign({}, userData);
        ud.Telefoni = e.target.value;
        setUserData(ud);
        setDisabled(true);

    };

    const changeImage = (e) => {
        // const ud = Object.assign({}, image);
        // ud.photo = e.target.files[0];
        // setImage(ud);
        if (e.target.files && e.target.files[0]) {
            setImage(URL.createObjectURL(e.target.files[0]));
        }
        const ud = Object.assign({}, userData);
        ud.llogo = e.target.files[0];
        setUserData(ud);
        console.log("picture", ud);
    };



    console.log("cust", props?.customer_id)


    const saveImage = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("logo", userData?.llogo);

        const config = {
            headers: {
                "content-type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
            },
        };

        axios
            .put(
                `/customer/changeLogo`, formData, config
            )
            .then((res) => {
                // console.log(res.data);
                toast.success("Fotoja është ndryshuar më sukses!");
                setButtonChange(true);
                setImage(null);
                console.log("response", res)
                getUserData();

            })
            .catch((err) => {
                toast.error("Something went wrong!");
                console.log(err);
            });
    };
    const changeButton = () => {
        setButtonChange(false);
    }









    // let hahahhiihi = window.localStorage.getItem('terminettoken');
    // let decoded = jwtDecode(hahahhiihi)
    // console.log('decoded', decoded)
    const submitUserData = (event) => {
        event.preventDefault()
        const formData = new FormData();
        formData.append("image", userData?.llogo);
        let profileEditData = {
            name: userData.DisplayName,
            web: userData.webSajti,
            address: userData.Adresa,
            email: userData.eMail,
            phone: userData.Telefoni,
            logo: userData.llogo
        }
        axios.put(`/customer/details`, profileEditData)
            .then(data => {
                setDisabled(false)
                toast.success("Your information is updated successfully!");
                setProfessionalInfo(data?.data)
                const formData = new FormData();
                setButtonChange(true);
                console.log('a po shkojn', data?.data);

            }).catch(err => {
                console.log("erroir", err);
                toast.error(err)
            });
        console.log('idrizi', userData)
    }

    console.log("first", userData)

    // const saveImage = (e) => {
    //     e.preventDefault();

    //     const formData = new FormData();
    //     formData.append("image", image.pic_url);

    //     const config = {
    //         headers: {
    //             "content-type": "multipart/form-data",
    //             "Access-Control-Allow-Origin": "*",
    //         },
    //     };


    // }

    if (loading === true) {
        return <Loader/>
        } else
    return (
        // <div className="container">
        <div className="editCompany"  style={{ left: props?.menu ? '20%' : '0', width: props?.menu ? "70%" : "80%" }}>
                    <h3 className="titujt" > Profili i Kompanisë</h3>
            <div className="companyProfileStatic" style={{ left: props?.menu ? '20%' : '0', width: props?.menu ? "70%" : "80%" }}>
                <div className="register-form">
                    <div className=" profile-image-box-V  text-center">
                        <div className="foto">


                            <img
                                    src={userData?.llogo === "null" ? comapny : (image === null ? `${process.env.REACT_APP_UPLOADS + userData?.llogo}`: image)}

                                alt="Logo e kompanis"
                                width="200px"
                            />
                        </div>
                        {/* <input type="file" onChange={changeImage} /> */}

                    </div>

                    {
                        // <label
                        //     htmlFor="profile-picture-input"
                        //     className="profile-picture-edit"
                        // >
                        //     <h6>

                        //         Ndrysho
                        //     </h6>
                        //     <input
                        //         type="file"
                        //         style={{ cursor: "pointer" }}
                        //         id="profile-picture-input"
                        //         onChange={changeImage}
                        //         className="hidden-hidden"
                        //     />
                        // </label>
                        <label
                            htmlFor="profile-picture-input"
                            className="profile-picture-editBtn"
                        >
                            <span className="buttonNdrysho">Ndrysho</span>
                            <input
                                type="file"
                                style={{ cursor: "pointer" }}
                                id="profile-picture-input"
                                onChange={changeImage}
                                className="hidden-hidden"
                            />
                        </label>
                    }

                    {!!image && <button onClick={saveImage} className="btn changePicBtn" > Ruaj </button>}
                    <form onSubmit={submitUserData} >

                        <div className="form-input">
                            <label htmlFor="phone" id="kushtet" className="kushtet text-left ">
                                Emri
                                <input
                                    className="form-control "
                                    type="text"
                                    placeholder={"Email"}
                                    defaultValue={userData?.DisplayName}
                                    onChange={name}
                                />
                            </label>

                            <label htmlFor="phone" id="kushtet" className="kushtet text-left ">
                                Adresa
                                <input
                                    className="form-control "
                                    type="text"
                                    placeholder={"Email"}
                                    defaultValue={userData?.Adresa}
                                    onChange={adresa}
                                />
                            </label>
                            <label htmlFor="phone" id="kushtet" className="kushtet text-left ">
                                E-mail:
                                <input
                                    className="form-control"
                                    type="email"
                                    placeholder={"Email"}
                                    defaultValue={userData?.eMail}
                                    onChange={email}
                                />
                            </label>
                            <label htmlFor="phone" id="kushtet" className="kushtet text-left">
                                Numri telefonit
                                <input
                                    type="text"
                                    className="form-control xona20"
                                    placeholder={"Numri i telefonit"}
                                    defaultValue={userData?.Telefoni}
                                    value={userData?.Telefoni}
                                    onChange={telefoni}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key) || (userData?.Telefoni?.length > 8)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </label>

                            <label htmlFor="phone" id="kushtet" className="kushtet text-left">
                                Webi
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={"emergencyContact"}
                                    defaultValue={userData?.webSajti}
                                    onChange={webSajti}
                                />
                            </label>
                            {/* <div className='productCapacity w-100'>
                              <a className="clinicLinks pr-1 pe-2 " href={"//" + filtered?.webSajti} target="_blank"><i class='fa fa-globe pt-1 pr-1 pe-2' />{filtered?.webSajti}</a>
                            </div> */}
                            <div className="form-group">
                                <button type="submit" disabled={!disabled} className="form-control btn btnLogin">Ruaj</button>
                            </div>

                        </div>
                    </form >
                </div >

            </div>
        </div >
        // </div>
    );
};
const mapStateToProps = state => {
    return {
        lang: state.data.lang,
        isLoading: state.data.isLoading,
        loggedIn: state.data.loggedIn,
        menu: state.data.menu,
        user_id: state.data.user_id,
        customer_id: state.data.customer_id,
        external_db: state.data.external_db,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        loggMeIN: () => dispatch(loggMeIN()),
        rmAuth: () => dispatch(rmAuth()),
        toggleMenu: () => dispatch(toggleMenu()),
        setExternalDB: () => dispatch(setExternalDB()),
        // loadingOff: () => dispatch(loadingOff()),
        setCustomerId: (customer_id) => dispatch(setCustomerId(customer_id)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(KompanyProfile);
// export default Stock;